import React, { useEffect } from "react";

import Option from "../components/Option";
import ButtonNext from "../components/ButtonNext";
import LinkNext from "../components/LinkNext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/sharp-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

export default function PreparedCalculator(props) {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    const handleOptionActive = (event, index, showSlider) => {
        props.setActivePreparedOption(index);
    }

    return (
        <section className="preparedBox bg-lightBlue w-100 pt-5">

            <div className="bg-lightBlue">
                <LinkNext link="kalkulator?scrollToCalculator" >Lukk <FontAwesomeIcon icon="fa-solid fa-xmark" size="1x" className="ms-1" /></LinkNext>
                <div className="container">
                    <div className="row justify-content-center text-center pt-5 mb-5">
                        <div className="col col-lg-6 col-xl-6 col-md-8">
                            <p className="fw-bold">Ting kan skje</p>
                            <h1 className="h1">ER DU <span className={`blueAfter ${props.operatingSystem ? "spanForMac" : ""}`}>FORBEREDT?</span></h1>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col col-lg-6 col-xl-6 col-md-8">
                            <Option
                                data={props.data.forberedtKalkulator}
                                activeOption={props.activePreparedOption}
                                setActiveOption={props.setActivePreparedOption}
                                handleOptionActive={handleOptionActive}
                                textCenter={true}
                            />
                        </div>
                    </div>


                    <div className="row justify-content-center">
                        <div className="col col-lg-5 col-xl-4 col-md-7 justify-content-center d-flex">
                            <ButtonNext link="forberedtKalkulator2" class="" />
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}