import React, { useState, useEffect } from "react";

import ButtonNext from "../components/ButtonNext";
import LinkNext from "../components/LinkNext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/sharp-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

export default function PreparedFeedback(props) {
    const [feedback, setFeedback] = useState('');

    const handleChoiceActive = (event, index) => {
        props.setActivePreparedFeedback(index)
    }

    useEffect(() => {
        if (props.activePreparedFeedback === 0) {
            setFeedback(props.data.forberedtKalkulator.stories[+props.activePreparedOption].feedback[0].reply)
        } else if (props.activePreparedFeedback === 1) {
            setFeedback(props.data.forberedtKalkulator.stories[+props.activePreparedOption].feedback[1].reply)
        } else if (props.activePreparedFeedback === 2) {
            setFeedback(props.data.forberedtKalkulator.stories[+props.activePreparedOption].feedback[2].reply)
        }
    })

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <section className="preparedBox bg-lightBlue w-100 pt-5">
            <div className="bg-lightBlue">
                <LinkNext link="kalkulator?scrollToCalculator" >Lukk <FontAwesomeIcon icon="fa-solid fa-xmark" size="1x" className="ms-1" /></LinkNext>
                <div className="container">
                    <div className="row justify-content-center text-center pt-5">
                        <div className="col col-lg-6 col-xl-6 col-md-8">
                            <p className="fw-bold">Ting kan skje</p>
                            <h1 className="h1">Dette kan jeg <span className={`blueAfter ${props.operatingSystem ? "spanForMac" : ""}`}>Ikke</span> gå glipp av</h1>
                        </div>
                    </div>

                    <div className="row justify-content-center mt-5 text-center">
                        <div className="col col-lg-6 col-xl-6 col-md-8">
                            <h5 className="text-uppercase fw-bold mb-0">Hva hadde du gjort i dette tilfellet?</h5>
                        </div>
                    </div>

                    <div className="row justify-content-center mt-3 mx-2">
                        <div className="col col-lg-6 col-xl-6 col-md-8">
                            {props.data.forberedtKalkulator.stories[+props.activePreparedOption].feedback.map((item, index) => (
                                <button className={`col-12 p-3 mb-2 text-center btn rounded-0 preparedFeecbackBox ${props.activePreparedFeedback === index ? 'optionActive' : ''}`}
                                    key={index} onClick={(event) => handleChoiceActive(event, index)}
                                    style={{ backgroundColor: "rgba(255,255,255,.6)" }}>
                                    <p className="mb-0">{item.text}</p>
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="row justify-content-center mt-4 mx-2">
                        <div className="col col-lg-6 col-xl-6 col-md-8">
                            <p class="fw-bold text-center">
                                {feedback}
                            </p>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col col-lg-5 col-xl-4 col-md-7 justify-content-center d-flex">
                            <ButtonNext link="kalkulator?scrollToCalculator" class="mt-auto" marginY="my-2" text="Lukk" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}