import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import Income from "../components/Income";
import LivingExpenses from "../components/LivingExpenses";
import TransportationExpenses from "../components/TransportationExpenses";
import Consumptions from "../components/Consumptions";
import Dept from "../components/Dept";
import Savings from "../components/Savings";
import EditEduPro from "../components/EditEduPro";
import EditListSaving from "../components/EditListSaving";
import { useSwipeable } from "react-swipeable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/sharp-solid-svg-icons';
import Feedback from "../components/Feedback";
import ConcentModal from "../components/ConcentModal";

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

export default function Calculator(props) {
    const [activateResult, setActivateResult] = useState(false);
    const [scrollToCalculator, setScrollToCalculator] = useState(false);
    const [scrollToCalculator2, setScrollToCalculator2] = useState(false);
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [activeAccordion2, setActiveAccordion2] = useState(null);
    const [dialogActive, setDialogActive] = useState(false);

    const [showResultPage, setShowResultPage] = useState(false);

    const [showHeaderContent, setShowHeaderContent] = useState(false);

    const [footerColor, setFooterColor] = useState("bg-info");
    const [footerBar, setFooterBar] = useState('');

    const location = useLocation();

    const navigate = useNavigate();


    let totalSavings = 0;
    for (let i = 0; i < props.savings.length; i++) {
        totalSavings += parseInt(props.savings[i].price);
    }

    let totalPrice = 0;
    for (let i = 0; i < props.savings.length; i++) {
        totalPrice += parseInt(props.savings[i].price);
    }


    useEffect(() => {
        props.setEndResult(
            props.income -
            props.rentalPrice -
            props.costInsurance -
            props.costPower -
            props.costTv -
            (+props.activeTransportationOption ? +props.costCarExpense : +props.costCollective) -
            (
                +props.costFood +
                +props.costGrocery +
                +props.costCare +
                +props.costGaming +
                +props.costActivities +
                +props.costClothes +
                +props.costFurniture
            ) -
            +props.studentLoanMonth -
            (sessionStorage.getItem('activeTransportationOption') != '0' ? +props.costCar : 0) -
            +props.savingsNormal -
            +props.savingsBsu

        );



        const totalMonths = Math.floor((totalSavings - (+props.preBsu + +props.preSavings)) / (+props.savingsNormal + +props.savingsBsu));
        const years = Math.floor(totalMonths / 12);
        const months = totalMonths % 12;

        /* props.setSavingsYear(years);
        props.setSavingsMonth(months); */

    }, [
        props.endResult,
        props.income,
        props.rentalPrice,
        props.costInsurance,
        props.costPower,
        props.costTv,
        props.costCollective,
        props.costCar,
        props.activeTransportationOption,
        props.costCarExpense,
        props.costFood,
        props.costGrocery,
        props.costCare,
        props.costGaming,
        props.costActivities,
        props.costClothes,
        props.costFurniture,
        props.savingsNormal,
        props.savingsBsu,
        props.preSavings,
        props.preBsu,
        props.studentLoanMonth,
        props.costHousing,
        props.savingsList
    ])

    const handleJobChange = (e) => {
        props.setJobTitle(e.target.value);
    };
    const handleCostFormChange = (e) => {
        const inputVal = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters

        if (!inputVal || isNaN(inputVal)) {
            props.setFormattedCostSalary('');
            props.setCostSalary(0)
        } else {
            const numericVal = parseInt(inputVal, 10);
            const numericValMonth = parseInt(inputVal / 12, 10)
            const formatted = numericVal.toLocaleString('no'); // Format number with thousand separator
            const formattedMonth = numericValMonth.toLocaleString('no')
            props.setFormattedCostSalary(formatted);
            props.setCostSalary(numericVal);

            props.setFormattedCostSalaryMonth(formattedMonth);
            props.setCostSalaryMonth(Math.round(numericValMonth));
        }
    };

    const handleEduSliderChange = (event) => {
        event.preventDefault();
        const value = parseFloat(event.target.value);

        props.setSliderValue(value);
    };

    const studieLan = props.sliderValue * props.data.education.studiear_slider.lan_per_ar;
    const studieLanMonth = parseInt(studieLan / 12 / 20) * (1 + (props.data.kalkulator_lan_og_gjeld.studielan.rate / 100));

    useEffect(() => {
        if (props.sliderValue === 0) {
            props.setStudentLoan(0);
            props.setStudentLoanMonth(0);
        } else {
            props.setStudentLoan(studieLan.toLocaleString('no'));
            props.setStudentLoanMonth(parseInt(studieLanMonth, 10));
        }
    })

    const [switchRediger, setSwitchRediger] = useState(false);
    const [switchRedigerSave, setSwitchRedigerSave] = useState(false);

    const handleRedigerYrkeCLick = (switchRediger) => {
        setSwitchRediger(switchRediger);
    }

    const handleRedigerSaveCLick = (switchRedigerSave) => {
        setSwitchRedigerSave(switchRedigerSave);
    }

    const handleBackRediger = (back) => {
        setSwitchRediger(back);
        setSwitchRedigerSave(back)
    }

    const handleInputChange = (e) => {
        const existingIndex = props.savings.findIndex(item => item.id === 0);

        const inputVal = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters

        if (existingIndex !== -1) {
            const updatedSavings = [...props.savings];
            updatedSavings[existingIndex] = {
                "id": 0,
                "icon": "fa-house",
                "title": "Bolig (Egenkapital)",
                "price": (inputVal * 0.15),
                "checked": true,
                "showDelete": false
            };

            props.setSavings(updatedSavings);
            props.setCostHousing(inputVal)

            if (!inputVal || isNaN(inputVal)) {
                props.setFormattedCostHousing('');
                props.setCostHousing(0);
            } else {
                const numericVal = parseInt(inputVal, 10);
                const formatted = numericVal.toLocaleString('no'); // Format number with thousand separator
                props.setFormattedCostHousing(formatted);
                props.setCostHousing(numericVal);
            }
        } else {
            const updatedSavings = [
                {
                    "id": 0,
                    "icon": "fa-house",
                    "title": "Bolig (Egenkapital)",
                    "price": (inputVal * 0.15),
                    "checked": true,
                    "showDelete": false
                },
                ...props.savings
            ];

            props.setSavings(updatedSavings);
            props.setCostHousing(inputVal)

            if (!inputVal || isNaN(inputVal)) {
                props.setFormattedCostHousing('');
                props.setCostHousing(0)
            } else {
                const numericVal = parseInt(inputVal, 10);
                const formatted = numericVal.toLocaleString('no'); // Format number with thousand separator
                props.setFormattedCostHousing(formatted);
                props.setCostHousing(numericVal);
            }
        }
    }

    /* CHECKS IF THE USER HAVE DONE ALL THEY ARE SUPPOSED TO DO AND LET THEM GO TO RESULT IF SO */
    useEffect(() => {
        if (((props.activeLivingOption == 0 && props.activeLivingOption !== '') || props.activeLivingOption == 1 || props.activeLivingOption == 2) && props.savingsNormal > 0 && props.endResult >= 0) {
            setActivateResult(true);
        } else {
            setActivateResult(false);
        }



    }, [props.preBsu, props.preSavings, props.savingsNormal, props.activeLivingOption, props.endResult]);

    /* USEREF TO OBTAIN A STABLE REFERENCE TO THE ELEMENT */
    const calculatorRef = useRef(null);
    const calculatorRef2 = useRef(null);

    /* HANDLER FOR ACCORDION CHANGE */
    const handleAccordionChange = (key) => {
        setActiveAccordion(key);

        if (activeAccordion != null) {
            window.scrollTo(0, 0)
        }
    };

    const handleAccordionChange2 = (key) => {
        setActiveAccordion2(key);
    }

    /* SCOLLING BACK TO ACCORDION WITH CALCULATORS WHEN GOING BACK TO MAIN CALCULATOR */
    useEffect(() => {
        if (location.search === '?scrollToCalculator') {
            setScrollToCalculator(true);
            setActiveAccordion('4');
        } else if (location.search === '?scrollToCalculator2') {
            setScrollToCalculator2(true);
            setActiveAccordion('3');
        } else {
            setScrollToCalculator(false);
            setScrollToCalculator2(false);
        }
    }, []);

    /* ADDEDING DELAY AND SCROLLING DOWN TO THE COMPONENT */
    useEffect(() => {
        if (scrollToCalculator && calculatorRef.current) {
            setTimeout(() => {
                calculatorRef.current.scrollIntoView();
            }, 310);
        }
    }, [scrollToCalculator]);

    useEffect(() => {
        if (scrollToCalculator2 && calculatorRef2.current) {
            setTimeout(() => {
                calculatorRef2.current.scrollIntoView();
            }, 310);
        }
    }, [scrollToCalculator2]);



    const handlersHeader = useSwipeable({
        onSwipedDown: () => setShowHeaderContent(true),
        onSwipedUp: () => setShowHeaderContent(false)
    });

    const handlers = {};

    if (activateResult) {
        handlers.onSwipedDown = () => setShowResultPage(false);
        handlers.onSwipedUp = () => setShowResultPage(true);
    }

    const handlersFooter = useSwipeable(handlers);


    const handleShowResultPage = () => {

        //props.setSavingsYear(years);
        //props.setSavingsMonth(months);




        setShowResultPage((curent) => !curent);



    };

    const handleShowHeaderContent = () => {
        setShowHeaderContent((curent) => !curent);
    }



    const headerBackgroundColor = () => {
        if (props.savingsYear >= 15) {
            return 'bg-red';
        } else if (props.savingsYear >= 10) {
            return 'bg-lightOrange';
        } else {
            return 'bg-lightGreen';
        }
    }


    /* const headerColor = () => {
        if (props.savingsYear >= 15) {
            return 'text-red';
        } else if (props.savingsYear >= 10) {
            return 'text-orange';
        } else {
            return 'text-green';
        }
    } */

    /* HIDE AND SHOW MODAL */
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = (event) => {
        setShowModal(false);
    }
    const handleShowModal = () => setShowModal(true);

    /* Footer Modals */
    const [showFooterModal, setShowFooterModal] = useState(false);
    const handleCloseFooterModal = (event) => {
        setShowFooterModal(false);
    }
    const handleShowFooterModal = () => setShowFooterModal(true);

    const [showFooterModal2, setShowFooterModal2] = useState(false);
    const handleCloseFooterModal2 = (event) => {
        setShowFooterModal2(false);
    }
    const handleShowFooterModal2 = () => setShowFooterModal2(true);

    const [showFooterModal3, setShowFooterModal3] = useState(false);
    const handleCloseFooterModal3 = (event) => {
        setShowFooterModal3(false);
    }
    const handleShowFooterModal3 = () => setShowFooterModal3(true);

    /* Changing footer color by the result */
    useEffect(() => {
        if (props.savingsYear >= 15) {
            setFooterColor('bg-red');
            setFooterBar('bar-red');
        } else if (props.savingsYear >= 10) {
            setFooterColor('bg-lightOrange');
            setFooterBar('bar-orange');
        } else {
            setFooterColor('bg-lightGreen');
            setFooterBar('bar-green');
        }


    }, [props.endResult, props.savingsYear, props.savingsMonth])

    const handleReset = () => {
        const shouldReset = window.confirm('Er du sikker på at du vil tilbakestille?');

        if (shouldReset) {
            sessionStorage.clear();
            navigate('/');

            /* ################################## Profession useState's ################################# */
            props.setJobTitle('');  // jobTitle
            props.setCostSalary('');  // costSalary
            props.setFormattedCostSalary('');  // formattedCostSalary
            props.setCostSalaryMonth('');  // costSalaryMonth
            props.setFormattedCostSalaryMonth('');  // formattedCostSalaryMonth

            /* ################################# Education useState's ###################################### */
            props.setShowSlider(false);  // showSlider
            props.setSliderValue(3);  // sliderValue
            props.setActiveOption('');  // activeOption
            props.setStudentLoan('');  // studentLoan
            props.setStudentLoanMonth('');  // studentLoanMonth

            /* ############################# SavingsList useState's ################################## */
            props.setSavings([
                {
                    "id": 0,
                    "icon": "fa-house",
                    "title": "Bolig (Egenkapital)",
                    "price": 0,
                    "checked": true,
                    "showDelete": false
                }
            ]);

            props.setCostHousing('');
            props.setFormattedCostHousing('');

            /* ################################ Calculator ########################################### */
            props.setIncome('');  // income
            props.setTaxrate(props.data.kalkulator_inntekt.skattetrekk.default);  // taxrate
            props.setTaxrateSliderValue(props.data.kalkulator_inntekt.skattetrekk.default);  // taxrateSliderValue

            /* Living Expenses */
            props.setShowLivingContent(0);  // showLivingContent
            props.setActiveLivingOption('');  // activeLivingOption
            props.setRentalPrice('');  // rentalPrice
            props.setFormattedRentalPrice('');  // formattedRentalPrice
            props.setCostInsurance('');  // costInsurance
            props.setInsuranceSliderValue(0);  // insuranceSliderValue
            props.setCostPower('');  // costPower
            props.setPowerSliderValue(0);  // powerSliderValue
            props.setCostTv('');  // costTv
            props.setTvSliderValue(0);  // tvSliderValue

            /* Transportation Expenses */
            props.setShowCarExpenseContent(0);  // showCarExpenseContent
            props.setActiveTransportationOption(0);  // activeTransportationOption
            props.setActiveCarExpenseOption('');  // activeCarExpenseOption
            props.setCostCollective(props.data.kalkulator_transportutgifter.utgifter_kollektivt.default);  // costCollective
            props.setCollectiveSliderValue(props.data.kalkulator_transportutgifter.utgifter_kollektivt.default);  // collectiveSliderValue
            props.setCostCar('');  // costCar
            props.setCarSliderValue(0);  // carSliderValue
            props.setCostCarExpense(0);  // costCarExpense
            props.setCarExpenseSliderValue(0);  // carExpensesSliderValue

            /* Consumptions Expenses */
            props.setCostFood('');  // costFood
            props.setFoodSliderValue(props.data.kalkulator_forbruk.categories[0].default);  // foodSliderValue
            props.setCostGrocery('');  // costGrocery
            props.setGrocerySliderValue(props.data.kalkulator_forbruk.categories[1].default);  // grocerySliderValue
            props.setCostClothes('');  // costClothes
            props.setClothesSliderValue(props.data.kalkulator_forbruk.categories[2].default);  // clothesSliderValue
            props.setCostCare('');  // costCare
            props.setCareSliderValue(props.data.kalkulator_forbruk.categories[3].default);  // careSliderValue
            props.setCostGaming('');  // costGaming
            props.setGamingSliderValue(props.data.kalkulator_forbruk.categories[4].default);  // gamingSliderValue
            props.setCostActivities('');  // costActivities
            props.setActivitiesSliderValue(props.data.kalkulator_forbruk.categories[5].default);  // activitesSliderValue
            props.setCostFurniture('');  // costFurniture
            props.setFurnitureSliderValue(props.data.kalkulator_forbruk.categories[6].default);  // furnitureSliderValue

            /* Savings */
            props.setSavingsNormal('');  // savingsNormal
            props.setFormattedSavingsNormal('');  // formattedSavingsNormal
            props.setSavingsBsu('');  // savingssBsu
            props.setSavingsBsuSliderValue(props.data.kalkulator_spare.manedlig_sparing_bsu.default);  // savingsBsuSliderValue
            props.setPreSavings('');  // preSavings
            props.setFormattedPreSavings('');  // formattedPreSavings
            props.setPreBsu('');  // preBsu
            props.setFormattedPreBsu('');  // formattedPreBsu

            /* End Result */
            props.setEndResult('');  // endResult
            props.setSavingsYear('');  // savingsYear
            props.setSavingsMonth('');  // savingsMonth
            props.setSavingsTitle('');  // savingsTitle
            props.setSavingsMessage('');  // savingsMessage
            props.setActiveResultOption(0);  // activeResultOption
            props.setActivePreparedFeedback('');  // activePreparedFeedback
            props.setConsumptionItems([]);  // consumptionItems

            /* Pepared Calculator */
            props.setActivePreparedOption('');

            // State to keep track of the total amount spent
            props.setTotalAmount(0);
        }
    }

    /* Removing scrollbar when footer result is open */
    const handleScrollBar = () => {
        const showDialog = () => {
            const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
            const body = document.body;
            body.style.position = 'fixed';
            body.style.width = '100%';
            body.style.top = `-${scrollY}`;
        };
        const closeDialog = () => {
            const body = document.body;
            const scrollY = body.style.top;
            body.style.position = '';
            body.style.width = '';
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }

        if (showResultPage) {
            showDialog();
        } else if (dialogActive) {
            closeDialog();
        }

        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });
    }

    useEffect(() => {
        if (showResultPage) {
            setDialogActive(true);
        } else {
            setDialogActive(false);
        }
    }, [showResultPage])

    return (
        <div className="calc-page position-relative" style={{ height: '100%' }}>
            <div className={`header-wrap ${showHeaderContent ? 'show-header' : ''}`}>
                <div class="header-content w-100 bg-white pt-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col col-lg-6 col-md-7">
                                <h1 className="h1 text-center text-uppercase pt-3 pb-4">
                                    DINE ØNSKER <br /> FOR <span className={`${props.operatingSystem ? "spanForMac" : ""}`}>FREMTIDEN</span>
                                </h1>
                            </div>
                        </div>

                        {(switchRediger === false) && (switchRedigerSave === false) ?
                            <div className="row justify-content-center inside-from mb-4 mx-2">
                                <div className="col-sm-9 col-lg-5 col-xl-4 lan-og-gjeld-box pt-3 pb-4 px-4 bg-grey">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="h5 fw-bold text-uppercase pt-4 mt-2">
                                            YRKE og UTDANNING
                                        </h5>
                                        <h6 className="text-capitalize fw-normal mb-4 h6 editButton" onClick={() => handleRedigerYrkeCLick(true)}>
                                            <FontAwesomeIcon icon="fa-sharp fa-solid fa-pen-to-square" className="me-1" />
                                            Rediger
                                        </h6>
                                    </div>
                                    <div className="">
                                        <p className="mb-1">Yrke: <span className="fw-bold">{props.jobTitle}</span></p>
                                        <p className="mb-1">Årslønn: <span className="fw-bold">{parseInt(props.costSalary).toLocaleString("no")},-</span></p>
                                        {
                                            props.studentLoan ?
                                                <p className="mb-1">Lån: <span className="fw-bold">{props.studentLoan},- </span>({props.sliderValue} år)</p>
                                                : <p className="mb-1">Lån: ingen</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            : null}

                        {(switchRediger === false) && (switchRedigerSave === false) ?
                            <div className="row justify-content-center inside-from mb-4 mx-2">
                                <div className="col-sm-9 col-lg-5 col-xl-4 lan-og-gjeld-box pt-3 pb-4 px-4 bg-grey">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="h5 fw-bold text-uppercase pt-4 mt-2">
                                            Dine sparemål
                                        </h5>
                                        <h6 className="text-capitalize fw-normal mb-4 h6 editButton me-1" onClick={() => handleRedigerSaveCLick(true)}>
                                            <FontAwesomeIcon icon="fa-sharp fa-solid fa-pen-to-square" className="" />
                                            Rediger
                                        </h6>
                                    </div>
                                    <div className="">
                                        {props.savings.map((item) => (
                                            <p className="mb-1 text-capitalize">{item.title} </p>
                                        ))}
                                        <label className="fw-bold">{totalPrice.toLocaleString('no')},-</label>
                                    </div>
                                </div>
                            </div>
                            : null}

                        {switchRediger ?

                            <EditEduPro jobTitle={props.jobTitle}
                                handleBackRediger={handleBackRediger}
                                onChange={handleJobChange}
                                onChangeCostForm={handleCostFormChange}
                                sliderValue={props.sliderValue}
                                studentLoan={props.studentLoan}
                                onChangeEduSlider={handleEduSliderChange}
                                dataYrke={props.dataYrke}
                                dataUtdaning={props.dataUtdaning}

                                costSalary={props.costSalary}
                                setCostSalary={props.setCostSalary}
                                formattedCostSalary={props.formattedCostSalary}
                                setFormattedCostSalary={props.setFormattedCostSalary}

                                costSalaryMonth={props.costSalaryMonth}
                                setCostSalaryMonth={props.setCostSalaryMonth}
                                formattedCostSalaryMonth={props.formattedCostSalaryMonth}
                                setFormattedCostSalaryMonth={props.setFormattedCostSalaryMonth}

                                savings={props.savings}
                            />
                            : null}

                        {switchRedigerSave ?

                            <EditListSaving
                                dataBolig={props.dataBolig}
                                handleBackRediger={handleBackRediger}
                                handleInputChange={handleInputChange}
                                dataList={props.dataList}
                                savingsList={props.savingsList}
                                setSavings={props.setSavings}
                                costHousing={props.costHousing}
                                formattedCostHousing={props.formattedCostHousing}
                                setCostHousing={props.setCostHousing}

                            />
                            : null}
                    </div>


                    <div className="container">
                        <div className="row text-center">
                            <Link to={'/'} />
                            <a className="text-danger text-decoration-underline" style={{ cursor: 'pointer' }} onClick={handleReset}>Nullstill og begynn på nytt</a>
                        </div>
                    </div>
                </div>

                <header {...handlersHeader} className="header-calc w-100"

                    onClick={() => handleShowHeaderContent()}
                >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 position-relative">
                                <div className="row py-3 py-md-2">
                                    <div className="col-2">
                                        <div className="header-calc_img me-3">
                                            <img src="./images/logo-calc.svg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="header-calc_content d-flex flex-column align-items-center justify-content-center h-100">
                                            <div className="mb-1 small"><span className="fw-semibold">{props.jobTitle}</span></div>
                                            <div className="row text-center w-100">
                                                <div className="col-6">
                                                    <div className="small">Sparemål:</div>
                                                    <div className="small fw-semibold">{totalSavings.toLocaleString('no')},-</div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="small">Årslønn:</div>
                                                    <div className="small fw-semibold">{props.formattedCostSalary},-</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="calculator-icon p-3 rounded-5">
                                            <FontAwesomeIcon icon="fa-sharp fa-solid fa-bullseye-arrow" size="xl" className={`calculator calculator-top ${showHeaderContent ? 'd-none' : ''}`}
                                                style={{ color: "#f6aa51" }} />
                                            <FontAwesomeIcon icon="fa-sharp fa-solid fa-angle-up" size="xl" className={`calculator calculator-bottom ${showHeaderContent ? '' : 'd-none'}`}
                                                style={{ color: "#707070" }} />
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </header>
            </div>


            <section className={`calc-list position-relative flex-column flex-grow`} onClick={handleScrollBar()}>
                <div className="container">
                    <div className="row justify-content-center text-center mb-2">
                        <div className={`col-lg-7 monthly_budget`} >
                            <h1 className="h2 pt-5 pt-lg-4 pb-3 pb-lg-0 w-100 mb-3">MÅNEDLIG BUDSJETT</h1>
                        </div>

                        <div className="col-lg-12">
                            <Accordion activeKey={activeAccordion} onSelect={handleAccordionChange}>
                                {/* INCOME */}
                                <Income
                                    data={props.data}

                                    taxrate={props.taxrate}
                                    setTaxrate={props.setTaxrate}
                                    costSalary={props.costSalary}
                                    setCostSalary={props.setCostSalary}
                                    costSalaryMonth={props.costSalaryMonth}
                                    setCostSalaryMonth={props.setCostSalaryMonth}

                                    income={props.income}
                                    setIncome={props.setIncome}
                                    taxrateSliderValue={props.taxrateSliderValue}
                                    setTaxrateSliderValue={props.setTaxrateSliderValue}

                                    formattedCostSalary={props.formattedCostSalary}
                                    setFormattedCostSalary={props.setFormattedCostSalary}
                                    formattedCostSalaryMonth={props.formattedCostSalaryMonth}
                                    setFormattedCostSalaryMonth={props.setFormattedCostSalaryMonth}

                                    expand={activeAccordion == 0 ? 'col-12 col-lg-8 p-0' : 'col-lg-6'}
                                    showActive={activeAccordion == null || activeAccordion == 0 ? 'd-block' : 'd-none'}
                                    showIcon={activeAccordion == 0 ? 'd-inline-block' : 'd-none'}
                                />

                                {/* LIVING EXPENSES */}
                                <LivingExpenses
                                    data={props.data}

                                    showLivingContent={props.showLivingContent}
                                    setShowLivingContent={props.setShowLivingContent}
                                    activeLivingOption={props.activeLivingOption}
                                    setActiveLivingOption={props.setActiveLivingOption}

                                    rentalPrice={props.rentalPrice}
                                    setRentalPrice={props.setRentalPrice}
                                    formattedRentalPrice={props.formattedRentalPrice}
                                    setFormattedRentalPrice={props.setFormattedRentalPrice}

                                    costInsurance={props.costInsurance}
                                    setCostInsurance={props.setCostInsurance}
                                    insuranceSliderValue={props.insuranceSliderValue}
                                    setInsuranceSliderValue={props.setInsuranceSliderValue}

                                    costPower={props.costPower}
                                    setCostPower={props.setCostPower}
                                    powerSliderValue={props.powerSliderValue}
                                    setPowerSliderValue={props.setPowerSliderValue}

                                    costTv={props.costTv}
                                    setCostTv={props.setCostTv}
                                    tvSliderValue={props.tvSliderValue}
                                    setTvSliderValue={props.setTvSliderValue}

                                    expand={activeAccordion == 1 ? 'col-12 col-lg-8 p-0' : 'col-lg-6'}
                                    showActive={activeAccordion == null || activeAccordion == 1 ? 'd-block' : 'd-none'}
                                />

                                {/* TRANSPORTATION EXPENSES */}
                                <TransportationExpenses
                                    data={props.data}

                                    showCarExpenseContent={props.showCarExpenseContent}
                                    setShowCarExpenseContent={props.setShowCarExpenseContent}

                                    activeTransportationOption={props.activeTransportationOption}
                                    setActiveTransportationOption={props.setActiveTransportationOption}
                                    activeCarExpenseOption={props.activeCarExpenseOption}
                                    setActiveCarExpenseOption={props.setActiveCarExpenseOption}

                                    costCollective={props.costCollective}
                                    setCostCollective={props.setCostCollective}
                                    collectiveSliderValue={props.collectiveSliderValue}
                                    setCollectiveSliderValue={props.setCollectiveSliderValue}

                                    costCar={props.costCar}
                                    setCostCar={props.setCostCar}
                                    carSliderValue={props.carSliderValue}
                                    setCarSliderValue={props.setCarSliderValue}

                                    setCostCarMonth={props.setCostCarMonth}

                                    costCarExpense={props.costCarExpense}
                                    setCostCarExpense={props.setCostCarExpense}
                                    carExpenseSliderValue={props.carExpenseSliderValue}
                                    setCarExpenseSliderValue={props.setCarExpenseSliderValue}

                                    expand={activeAccordion == 2 ? 'col-12 col-lg-8 p-0' : 'col-lg-6'}
                                    showActive={activeAccordion == null || activeAccordion == 2 ? 'd-block' : 'd-none'}
                                />

                                {/* OTHER EXPENSES */}
                                <Consumptions
                                    data={props.data}

                                    costFood={props.costFood}
                                    setCostFood={props.setCostFood}
                                    foodSliderValue={props.foodSliderValue}
                                    setFoodSliderValue={props.setFoodSliderValue}

                                    costGrocery={props.costGrocery}
                                    setCostGrocery={props.setCostGrocery}
                                    grocerySliderValue={props.grocerySliderValue}
                                    setGrocerySliderValue={props.setGrocerySliderValue}

                                    costClothes={props.costClothes}
                                    setCostClothes={props.setCostClothes}
                                    clothesSliderValue={props.clothesSliderValue}
                                    setClothesSliderValue={props.setClothesSliderValue}

                                    costCare={props.costCare}
                                    setCostCare={props.setCostCare}
                                    careSliderValue={props.careSliderValue}
                                    setCareSliderValue={props.setCareSliderValue}

                                    costGaming={props.costGaming}
                                    setCostGaming={props.setCostGaming}
                                    gamingSliderValue={props.gamingSliderValue}
                                    setGamingSliderValue={props.setGamingSliderValue}

                                    costActivities={props.costActivities}
                                    setCostActivities={props.setCostActivities}
                                    activitiesSliderValue={props.activitiesSliderValue}
                                    setActivitiesSliderValue={props.setActivitiesSliderValue}

                                    /* costHousehold={props.costHousehold}
                                    setCostHousehold={props.setCostHousehold}
                                    householdSliderValue={props.householdSliderValue}
                                    setHouseholdSliderValue={props.setHouseholdSliderValue} */

                                    costFurniture={props.costFurniture}
                                    setCostFurniture={props.setCostFurniture}
                                    furnitureSliderValue={props.furnitureSliderValue}
                                    setFurnitureSliderValue={props.setFurnitureSliderValue}

                                    calculatorRef2={calculatorRef2}


                                    operatingSystem={props.operatingSystem}

                                    expand={activeAccordion == 3 ? 'col-12 col-lg-8 p-0' : 'col-lg-6'}
                                    showActive={activeAccordion == null || activeAccordion == 3 ? 'd-block' : 'd-none'}

                                />

                                {/* LOAN AND DEPTS */}
                                <Dept
                                    data={props.data}

                                    studentLoan={props.studentLoan}
                                    studentLoanMonth={props.studentLoanMonth}
                                    sliderValue={props.sliderValue}

                                    activeTransportationOption={props.activeTransportationOption}
                                    costCar={props.costCar}
                                    carSliderValue={props.carSliderValue}
                                    setCarSliderValue={props.setCarSliderValue}

                                    setSliderValue={props.setSliderValue}

                                    calculatorRef={calculatorRef}

                                    operatingSystem={props.operatingSystem}


                                    expand={activeAccordion == 4 ? 'col-12 col-lg-8 p-0' : 'col-lg-6'}
                                    showActive={activeAccordion == null || activeAccordion == 4 ? 'd-block' : 'd-none'}

                                />

                                {/* SAVINGS */}
                                <Savings
                                    data={props.data}

                                    savingsNormal={props.savingsNormal}
                                    setSavingsNormal={props.setSavingsNormal}

                                    savingsBsu={props.savingsBsu}
                                    setSavingsBsu={props.setSavingsBsu}
                                    savingsBsuSliderValue={props.savingsBsuSliderValue}
                                    setSavingsBsuSliderValue={props.setSavingsBsuSliderValue}

                                    preBsu={props.preBsu}
                                    setPreBsu={props.setPreBsu}

                                    preSavings={props.preSavings}
                                    setPreSavings={props.setPreSavings}

                                    formattedSavingsNormal={props.formattedSavingsNormal}
                                    setFormattedSavingsNormal={props.setFormattedSavingsNormal}
                                    formattedPreSavings={props.formattedPreSavings}
                                    setFormattedPreSavings={props.setFormattedPreSavings}
                                    formattedPreBsu={props.formattedPreBsu}
                                    setFormattedPreBsu={props.setFormattedPreBsu}

                                    expand={activeAccordion == 5 ? 'col-12 col-lg-8 p-0' : 'col-lg-6'}
                                    showActive={activeAccordion == null || activeAccordion == 5 ? 'd-block' : 'd-none'}
                                />
                            </Accordion>
                        </div>
                    </div>
                    <ConcentModal />
                </div>

                <div className={`bg-white calculator-footer fixed-bottom w-100 ${showResultPage ? 'show-footer2' : ''}`} style={{ bottom: "0px" }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="ps-lg-0 col-lg-6 py-3 d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    {/* {activateResult ? (
                                            <div className={`calculator-icon p-3 rounded-5 me-2 ${headerBackgroundColor()} ${headerColor()}`}>
                                                <FontAwesomeIcon icon="fa-solid fa-calculator" size="xl" className={`calculator scale-icon ${showResultPage ? 'd-none' : ''}`} />
                                                <FontAwesomeIcon icon="fa-sharp fa-solid fa-angle-down" size="xl" className={`calculator calculator-rotate-0 calculator-bottom ${showResultPage ? '' : 'd-none'}`}
                                                    style={{ color: "#707070" }} />
                                            </div>
                                        ) : (
                                            <div className="calculator-icon p-3 rounded-5 me-2" >
                                                <FontAwesomeIcon icon="fa-solid fa-calculator" size="xl" className="calculator text-black" style={{ filter: 'invert(90%)' }} />
                                            </div>
                                        )} */}
                                    {props.endResult == 0 ? (
                                        <div className="fw-semibold ms-2" style={{ marginTop: "0px" }}>Ditt månedsbudsjett går opp!</div>
                                    ) : (
                                        <div className="fw-semibold ms-2" style={{ marginTop: "0px" }}>Til overs hver måned:</div>
                                    )}
                                </div>

                                {props.endResult < 0 ? (
                                    <div class="d-flex justify-content-center align-items-center">
                                        <h5 className="text-end fw-bold mb-0 h5 orangeFooterText" style={{ marginTop: "4px" }}>
                                            {props.endResult.toLocaleString('no')},-
                                        </h5>
                                        <FontAwesomeIcon icon="fa-solid fa-exclamation" inverse="100" size="sm" style={{ backgroundColor: '#F36F1C', padding: '0.2rem', aspectRatio: '1/1', zIndex: '100' }} className="rounded-5 ms-2" onClick={handleShowFooterModal} />
                                    </div>
                                ) : (
                                    null
                                )}

                                {props.endResult > 0 && activateResult ? (
                                    <div class="d-flex justify-content-center align-items-center">
                                        <h5 className="text-end fw-bold mb-0 h5 total" style={{ marginTop: "4px" }}>
                                            + {props.endResult.toLocaleString('no')},-
                                        </h5>
                                        <FontAwesomeIcon
                                            icon="fa-solid fa-question"
                                            inverse="100"
                                            size="sm"
                                            style={{ backgroundColor: '#1D2655', padding: '0.15rem' }}
                                            className="rounded-5 ms-2"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleShowFooterModal2();
                                            }}
                                        />
                                    </div>
                                ) : (
                                    null
                                )}

                                {props.endResult > 0 && !activateResult ? (
                                    <div class="d-flex justify-content-center align-items-center">
                                        <h5 className="text-end fw-bold mb-0 h5" style={{ marginTop: "4px" }}>
                                            + {props.endResult.toLocaleString('no')},-
                                        </h5>
                                        <FontAwesomeIcon
                                            icon="fa-solid fa-question"
                                            inverse="100"
                                            size="sm"
                                            style={{ backgroundColor: '#1D2655', padding: '0.15rem' }}
                                            className="rounded-5 ms-2"
                                            onClick={(event) => {
                                                event.stopPropagation(); // Prevent the event from reaching the footer
                                                handleShowFooterModal3(); // Handle the icon click event here
                                            }}
                                        />
                                    </div>
                                ) : (
                                    null
                                )}

                                {props.endResult == 0 && activateResult ? (
                                    <div class="d-flex justify-content-center align-items-center">
                                        <h5 className="text-end fw-bold mb-0 h5 total" style={{ marginTop: "4px" }}>
                                            {props.endResult.toLocaleString('no')},-
                                        </h5>
                                    </div>
                                ) : (
                                    null
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className={`footer-wrap ${showResultPage ? 'show-footer' : ''} ${activateResult ? 'visible' : 'invisible'}`}>

                <footer {...(activateResult && props.endResult >= 0 ? handlersFooter : {})} className={`calc-footer w-100 ${activateResult ? "" : "hide-before"}`}

                    onClick={activateResult ? () => handleShowResultPage() : null}
                >

                    <div className={`footer-result text-center pt-1 ${footerBar} ${footerColor}`}>
                        <p className="small mb-0 pb-2 pt-3">{showResultPage ? 'Lukk resultat' : 'Åpne ditt resultat'}</p>
                    </div>



                    {/* MINUS MODAL */}
                    <Modal show={showFooterModal} onHide={handleShowFooterModal} fullscreen={true}>
                        <Modal.Body className="orangeModal">
                            <div className="new-savings orange d-flex justify-content-center">
                                <FontAwesomeIcon
                                    className="closeIcon bg-darkBlue rounded-5 p-3"
                                    icon="fa-close"
                                    inverse="100%"
                                    size="2x"
                                    onClick={(event) => {
                                        event.stopPropagation(); // Prevent the event from reaching the footer
                                        handleCloseFooterModal(); // Handle the click event here
                                    }}

                                ></FontAwesomeIcon>
                                <form>

                                    <div className="new-savings__controls">

                                        <div className="row justify-content-center">
                                            <div className="col-12 col-lg-6 col-xl-6 col-md-8">
                                                <h2 className="fw-bold text-center mb-3">
                                                    Hmm… <br></br>Du går i minus hver måned!
                                                </h2>
                                            </div>

                                            <div className="row justify-content-center">
                                                <div className="col-12 text-center col-lg-6 col-xl-6 col-md-8">
                                                    <p>Det fungerer dårlig. Her må du nok justere på beløpene du har lagt inn slik at du får budsjettet til å gå opp.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col col-lg-6 col-xl-6 col-md-8">
                                            <div className="new-savings__action text-center mt-3">
                                                <a
                                                    className="btn bg-darkBlue text-white rounded-5 px-5 py-2 fw-bold"
                                                    type="submit"
                                                    onClick={(event) => {
                                                        event.stopPropagation(); // Prevent the event from reaching the footer
                                                        handleCloseFooterModal(); // Handle the click event here
                                                    }}
                                                >
                                                    Lukk
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Modal.Body>
                    </Modal>

                    {/* Penger til overs */}
                    <Modal show={showFooterModal2} onHide={handleShowFooterModal2} fullscreen={true}>
                        <Modal.Body>
                            <div className="new-savings d-flex justify-content-center">
                                <FontAwesomeIcon
                                    className="closeIcon bg-darkBlue rounded-5 p-3"
                                    icon="fa-close"
                                    inverse="100%"
                                    size="2x"
                                    onClick={(event) => {
                                        event.stopPropagation(); // Prevent the event from reaching the footer
                                        handleCloseFooterModal2(); // Handle the click event here
                                    }}
                                ></FontAwesomeIcon>
                                <form>

                                    <div className="new-savings__controls">

                                        <div className="row justify-content-center">
                                            <div className="col-12 col-lg-6 col-xl-6 col-md-8">
                                                <h2 className="fw-bold text-center mb-3">
                                                    Vi ser du har penger til overs!
                                                </h2>
                                            </div>

                                            <div className="row justify-content-center">
                                                <div className="col-12 text-center col-lg-6 col-xl-6 col-md-8">
                                                    <p>Vurder om dette kan settes av til sparing.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col col-lg-6 col-xl-6 col-md-8">
                                            <div className="new-savings__action text-center mt-3">
                                                <a
                                                    className="btn bg-darkBlue text-white rounded-5 px-5 py-2 fw-bold"
                                                    type="submit"
                                                    onClick={(event) => {
                                                        event.stopPropagation(); // Prevent the event from reaching the footer
                                                        handleCloseFooterModal2(); // Handle the click event here
                                                    }}
                                                >
                                                    Lukk
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Modal.Body>
                    </Modal>

                    {/* Noe som mangler */}
                    <Modal show={showFooterModal3} onHide={handleShowFooterModal3} fullscreen={true}>
                        <Modal.Body>
                            <div className="new-savings d-flex justify-content-center">
                                <FontAwesomeIcon
                                    className="closeIcon bg-darkBlue rounded-5 p-3"
                                    icon="fa-close"
                                    inverse="100%"
                                    size="2x"
                                    onClick={(event) => {
                                        handleCloseFooterModal3(); // Handle the click event here
                                        event.stopPropagation(); // Prevent the event from reaching the footer
                                    }}
                                ></FontAwesomeIcon>
                                <form>

                                    <div className="new-savings__controls">

                                        <div className="row justify-content-center">
                                            <div className="col-12 col-lg-6 col-xl-6 col-md-8">
                                                <h2 className="fw-bold text-center mb-3">
                                                    Du har litt jobb å gjøre!
                                                </h2>
                                            </div>

                                            <div className="row justify-content-center">
                                                <div className="col-12 text-center col-lg-6 col-xl-6 col-md-8">
                                                    <p> Du må minimum velge en boform og sette en månedlig sparesum for å få et inntrykk av hvordan budsjettet ditt går opp.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col col-lg-6 col-xl-6 col-md-8">
                                            <div className="new-savings__action text-center mt-3">
                                                <a
                                                    className="btn bg-darkBlue text-white rounded-5 px-5 py-2 fw-bold"
                                                    type="submit"
                                                    onClick={(event) => {
                                                        handleCloseFooterModal3(); // Handle the click event here
                                                        event.stopPropagation(); // Prevent the event from reaching the footer
                                                    }}
                                                >
                                                    Lukk
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Modal.Body>
                    </Modal>
                </footer>

                <div className={`resultPage`}
                >
                    <Feedback
                        data={props.data}
                        income={props.income}

                        savingsList={props.savingsList}
                        setSavings={props.setSavings}
                        costHousing={props.costHousing}

                        savingsYear={props.savingsYear}
                        setSavingsYear={props.setSavingsYear}
                        savingsMonth={props.savingsMonth}
                        setSavingsMonth={props.setSavingsMonth}

                        savingsTitle={props.savingsTitle}
                        setSavingsTitle={props.setSavingsTitle}
                        savingsMessage={props.savingsMessage}
                        setSavingsMessage={props.setSavingsMessage}

                        activeResultOption={props.activeResultOption}
                        setActiveResultOption={props.setActiveResultOption}

                        rentalPrice={props.rentalPrice}
                        costInsurance={props.costInsurance}
                        costPower={props.costPower}
                        costTv={props.costTv}

                        activeTransportationOption={props.activeTransportationOption}
                        costCarExpense={props.costCarExpense}
                        costCollective={props.costCollective}

                        costFood={props.costFood}
                        costGrocery={props.costGrocery}
                        costCare={props.costCare}
                        costGaming={props.costGaming}
                        costActivities={props.costActivities}
                        costClothes={props.costClothes}
                        costFurniture={props.costFurniture}
                        /* costHousehold={props.costHousehold} */

                        costCar={props.costCar}
                        studentLoanMonth={props.studentLoanMonth}

                        savingsNormal={props.savingsNormal}
                        savingsBsu={props.savingsBsu}

                        preBsu={props.preBsu}
                        preSavings={props.preSavings}

                        headerBackgroundColor={headerBackgroundColor}
                    />
                </div>
            </div>




        </div>

    )
}