import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import Range from './Range';
import ButtonNext from './ButtonNext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

const Consumptions = (props) => {

    const [accOpen, setAccOpen] = useState(false);

    const handleEntered = () => {
        setAccOpen(true);
    }


    /* Food And Drinks */
    const handleFoodChange = (event) => {
        event.preventDefault();
        const value = (event.target.value)

        props.setFoodSliderValue(value)
    }

    const food = props.foodSliderValue;
    useEffect(() => {
        props.setCostFood(food);
    }, [food, props.setCostFood])
    /* --------------- */

    /* Grocery */
    const handleGroceryChange = (event) => {
        event.preventDefault();
        const value = (event.target.value)

        props.setGrocerySliderValue(value)
    }

    const grocery = props.grocerySliderValue;
    useEffect(() => {
        props.setCostGrocery(grocery);
    }, [grocery, props.setCostGrocery])
    /* --------------- */

    /* Clothes */
    const handleClothesChange = (event) => {
        event.preventDefault();
        const value = (event.target.value)

        props.setClothesSliderValue(value)
    }

    const clothes = props.clothesSliderValue;
    useEffect(() => {
        props.setCostClothes(clothes);
    }, [clothes, props.setCostClothes])
    /* --------------- */

    /* Care */
    const handleCareChange = (event) => {
        event.preventDefault();
        const value = (event.target.value)

        props.setCareSliderValue(value)
    }

    const care = props.careSliderValue;
    useEffect(() => {
        props.setCostCare(care);
    }, [care, props.setCostCare])
    /* --------------- */

    /* Gaming */
    const handleGamingChange = (event) => {
        event.preventDefault();
        const value = (event.target.value)

        props.setGamingSliderValue(value)
    }

    const gaming = props.gamingSliderValue;
    useEffect(() => {
        props.setCostGaming(gaming);
    }, [gaming, props.setCostGaming])
    /* --------------- */

    /* Activities */
    const handleActivitiesChange = (event) => {
        event.preventDefault();
        const value = (event.target.value)

        props.setActivitiesSliderValue(value)
    }

    const activities = props.activitiesSliderValue;
    useEffect(() => {
        props.setCostActivities(activities);
    }, [activities, props.setCostActivities])
    /* --------------- */

    /* Household */
    /* const handleHouseholdChange = (event) => {
        event.preventDefault();
        const value = (event.target.value)

        props.setHouseholdSliderValue(value)
    }

    const household = props.householdSliderValue;
    useEffect(() => {
        props.setCostHousehold(household);
    }, [household, props.setCostHousehold]) */
    /* --------------- */

    /* Care */
    const handleFurnitureChange = (event) => {
        event.preventDefault();
        const value = (event.target.value)

        props.setFurnitureSliderValue(value)
    }

    const furniture = props.furnitureSliderValue;
    useEffect(() => {
        props.setCostFurniture(furniture);
    }, [furniture, props.setCostFurniture])
    /* --------------- */

    return (
        <Accordion.Item eventKey="3" className={props.showActive}>
            <div className="row justify-content-center accordion-header-style">
                <div className={props.expand}>
                    <Accordion.Header className='w-100'>
                        <div className="calc-list_header d-flex justify-content-between align-items-center w-100">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon="fa-solid fa-credit-card" size="lg" style={{ color: "#142656", }} />
                                <label className="ms-3 calc-acc-label">Forbruk</label>
                            </div>
                            <label className="ms-auto me-3 fw-bold">
                                {
                                    props.costFood > 0 ||
                                        props.costGrocery > 0 ||
                                        props.costCare > 0 ||
                                        props.costGaming > 0 ||
                                        props.costActivities > 0 ||
                                        props.costClothes > 0 ||
                                        props.costFurniture > 0 ? '- ' : null
                                }
                                {
                                    (+props.costFood +
                                        +props.costGrocery +
                                        +props.costCare +
                                        +props.costGaming +
                                        +props.costActivities +
                                        +props.costClothes +
                                        +props.costFurniture).toLocaleString('no')
                                },-
                            </label>
                        </div>
                    </Accordion.Header>
                </div>
            </div>


            <Accordion.Body in={accOpen}
                onEntered={handleEntered} className="px-0">


                <div className="row justify-content-center mb-5 mt-3">
                    <div className="col col-lg-6 col-xl-6 col-md-8">
                        <div className="d-flex bg-lightGreen p-3 green-tips">
                            <FontAwesomeIcon icon="lightbulb" inverse="100%" className="bg-green p-2 rounded-5 infobox-icon" />
                            <div className="text-start ps-3">
                                {/* Title for infobox */}
                                <span className="fw-medium">{props.data.kalkulator_forbruk.message}</span>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center mt-4">
                    {/* Food and drinks */}
                    {accOpen ?
                        <Range
                            data={props.data.kalkulator_forbruk.categories[0]}
                            onChange={handleFoodChange}
                            sliderValue={props.foodSliderValue}
                            className="position-relative"
                            object="kr"
                            id="foodRange"
                            accOpen={accOpen}
                            setAccOpen={setAccOpen}
                        />
                        :
                        <Range
                            data={props.data.kalkulator_forbruk.categories[0]}
                            onChange={handleFoodChange}
                            sliderValue="3500"
                            className="position-relative"
                            object="kr"
                            id="foodRange"
                            accOpen={accOpen}
                            setAccOpen={setAccOpen}
                        />}
                </div>
                <div className="row justify-content-center mt-4">

                    {/* Grocery */}
                    {accOpen ?
                        <Range
                            data={props.data.kalkulator_forbruk.categories[1]}
                            onChange={handleGroceryChange}
                            sliderValue={props.grocerySliderValue}
                            className="position-relative"
                            object="kr"
                            id="groceryRange"
                            accOpen={accOpen}
                            setAccOpen={setAccOpen}
                        />
                        :
                        <Range
                            data={props.data.kalkulator_forbruk.categories[1]}
                            onChange={handleGroceryChange}
                            sliderValue="380"
                            className="position-relative"
                            object="kr"
                            id="groceryRange"
                            accOpen={accOpen}
                            setAccOpen={setAccOpen}
                        />}

                </div>


                {/* Clothes and accessories */}
                <div className="row justify-content-center mt-4">
                    {accOpen ?
                        <Range
                            data={props.data.kalkulator_forbruk.categories[2]}
                            onChange={handleClothesChange}
                            sliderValue={props.clothesSliderValue}
                            className="position-relative"
                            object="kr"
                            id="clothesRange"
                            accOpen={accOpen}
                            setAccOpen={setAccOpen}
                        />
                        :
                        <Range
                            data={props.data.kalkulator_forbruk.categories[2]}
                            onChange={handleClothesChange}
                            sliderValue="970"
                            className="position-relative"
                            object="kr"
                            id="clothesRange"
                            accOpen={accOpen}
                            setAccOpen={setAccOpen}
                        />}
                </div>

                {/* Health Care */}
                <div className="row justify-content-center mt-4">
                    {accOpen ?
                        <Range
                            data={props.data.kalkulator_forbruk.categories[3]}
                            onChange={handleCareChange}
                            sliderValue={props.careSliderValue}
                            className="position-relative"
                            object="kr"
                            id="healthRange"
                            accOpen={accOpen}
                            setAccOpen={setAccOpen}
                        />
                        :
                        <Range
                            data={props.data.kalkulator_forbruk.categories[3]}
                            onChange={handleCareChange}
                            sliderValue="920"
                            className="position-relative"
                            object="kr"
                            id="healthRange"
                            accOpen={accOpen}
                            setAccOpen={setAccOpen}
                        />}
                </div>

                {/* Gaming */}
                <div className="row justify-content-center mt-4">
                    {accOpen ?
                        <Range
                            data={props.data.kalkulator_forbruk.categories[4]}
                            onChange={handleGamingChange}
                            sliderValue={props.gamingSliderValue}
                            className="position-relative"
                            object="kr"
                            id="gamingRange"
                            accOpen={accOpen}
                            setAccOpen={setAccOpen}
                        />
                        :
                        <Range
                            data={props.data.kalkulator_forbruk.categories[4]}
                            onChange={handleGamingChange}
                            sliderValue="500"
                            className="position-relative"
                            object="kr"
                            id="gamingRange"
                            accOpen={accOpen}
                            setAccOpen={setAccOpen}
                        />}
                </div>

                {/* Activities */}
                <div className="row justify-content-center mt-4">
                    {accOpen ?
                        <Range
                            data={props.data.kalkulator_forbruk.categories[5]}
                            onChange={handleActivitiesChange}
                            sliderValue={props.activitiesSliderValue}
                            className="position-relative"
                            object="kr"
                            id="activitiesRange"
                            accOpen={accOpen}
                            setAccOpen={setAccOpen}
                        />
                        :
                        <Range
                            data={props.data.kalkulator_forbruk.categories[6]}
                            onChange={handleActivitiesChange}
                            sliderValue="650"
                            className="position-relative"
                            object="kr"
                            id="activitiesRange"
                            accOpen={accOpen}
                            setAccOpen={setAccOpen}
                        />}
                </div>


                {/* Furniture */}
                <div className="row justify-content-center mt-4">
                    {accOpen ?
                        <Range
                            data={props.data.kalkulator_forbruk.categories[6]}

                            onChange={handleFurnitureChange}
                            sliderValue={props.furnitureSliderValue}
                            className="position-relative"
                            object="kr"
                            id="furnitureRange"
                            accOpen={accOpen}
                            setAccOpen={setAccOpen}
                        />
                        :
                        <Range
                            data={props.data.kalkulator_forbruk.categories[6]}

                            onChange={handleFurnitureChange}
                            sliderValue="520"
                            className="position-relative"
                            object="kr"
                            id="furnitureRange"
                            accOpen={accOpen}
                            setAccOpen={setAccOpen}
                        />}
                </div>

                <div className="row justify-content-center">
                    <div className="col col-lg-6 col-xl-6 col-md-8">
                        <div className="preparedCalculatorBox bg-purple py-4 px-4" ref={props.calculatorRef2}>
                            <h2 className="h2">Hva bruker du <span className={`pinkAfter ${props.operatingSystem ? "spanForMac" : ""}`}>Penger</span> på?</h2>
                            <p>Et budsjett skal ha rom for litt kos. Men kos kan også gå over i sløsing. Sjekk vår sløsekalkulator for å se hvor mye penger du bruker på kos.
                            </p>
                            <ButtonNext link="forbrukskalkulator" text="Til kalkulatoren" marginY="my-1"></ButtonNext>
                        </div>

                    </div>

                
                    {/* <div className="row justify-content-center">
                        <div className="col col-lg-6 col-xl-6 col-md-8">
                            <div className="preparedCalculatorBox bg-purple py-4 px-4" ref={props.calculatorRef2}>
                                <h2 className="h2">Hva bruker du <span className={`pinkAfter ${props.operatingSystem ? "spanForMac" : ""}`}>Penger</span> på?</h2>
                                <p>Et budsjett skal ha rom for litt kos. Men kos kan også gå over i sløsing. Sjekk vår sløsekalkulator for å se hvor mye penger du bruker på kos.
                                </p>
                                <ButtonNext link="forbrukskalkulator" text="Til kalkulatoren" marginY="my-1"></ButtonNext>                            
                            </div>                            
                        </div>                        
                    </div> */}
                
                

                </div>



            </Accordion.Body>
        </Accordion.Item>
    );
};

export default Consumptions;