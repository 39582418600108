import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';

const SavingsForm = (props) => {
    const [enteredTitle, setEnteredTitle] = useState('');
    const [enteredPrice, setEnteredPrice] = useState('');
    const [formattedEnteredPrice, setFormattedEnteredPrice] = useState('');
    const [showForm, setShowForm] = useState(0);

    const { data } = props;

    const handleInputChange = (identifier, value) => {
        if (identifier === 'title') {
            setEnteredTitle(value)
        } else {
            const inputVal = value.replace(/\D/g, '');;
            if (!inputVal || isNaN(inputVal)) {
                setFormattedEnteredPrice('');
                setEnteredPrice(0);
            } else {
                const numericVal = parseInt(inputVal.replace(/\D/g, ''), 10); // Remove non-numeric characters
                const formatted = numericVal.toLocaleString('no'); // Format number with thousand separator
                setFormattedEnteredPrice(formatted);
                setEnteredPrice(numericVal);
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const savingsData = {
            icon: 'fa-sharp fa-piggy-bank',
            title: enteredTitle,
            price: enteredPrice,
            checked: "checked"
        };

        props.onSaveSavingsData(savingsData);
        setEnteredTitle('');
        setEnteredPrice('');

        handleFormControl('hideForm');
    };

    const handleFormControl = (state) => {
        if (state === 'showForm') {
            setShowForm(1);
        } else {
            setShowForm(0);
        };
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="row justify-content-center">
                <div className="col col-lg-6 col-xl-5 col-md-8">
                    <div className="new-savings__controls">
                        <h2 className="fw-bold mb-5 text-center">
                            {data.title}
                        </h2>
                        <div className="new-savings__control mt-5">
                            <h6 className="h6 fw-bold">
                                {data.hva_sparer_du_til.title}
                            </h6>
                            <input
                                className="form-control"
                                type="text"
                                value={enteredTitle}
                                onChange={(event) => handleInputChange('title', event.target.value)}
                                placeholder={data.hva_sparer_du_til.placeholder}
                                maxLength={40}
                            ></input>
                        </div>
                        <div className="new-savings__control mt-4">
                            <h6 className="h6 fw-bold">{data.pris.title}</h6>
                            <input
                                className="form-control"
                                type="text"
                                value={formattedEnteredPrice}
                                onChange={(event) => handleInputChange('price', event.target.value)}
                                placeholder={data.pris.placeholder}
                                min={data.pris.min_value}
                                max={data.pris.max_value}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col col-lg-6 col-xl-5 col-md-8">
                        <div className="new-savings__action text-center mt-5">
                            <button

                                className={`btn  rounded-5 px-5 py-3 ${enteredPrice && enteredTitle ? 'fw-semibold text-white form_button__item bg-darkBlue' : 'fw-bold form_button__item-disabled'}`}

                                type="submit"
                                onClick={props.handleClose}
                            >
                                Lagre <FontAwesomeIcon icon={faFloppyDisk} size="sm" className="ms-2 mb-0"></FontAwesomeIcon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default SavingsForm;