import React, { useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

export default function CostForm(props) {

    const { data } = props;

    const [formattedValue, setFormattedValue] = useState('');

    return (
        <div className="row justify-content-center">
            <div className="col col-lg-5 col-xl-4 col-md-7">
                <div className="">
                    <h6 className={`h6 text-center d-block mb-2 fw-bold ${props.textAlign ? props.textAlign : null}`}>
                        {data.title}
                    </h6>

                    <div className="input-group mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={data.placeholder}
                            aria-label="Amount"
                            min={data.min_value}
                            max={data.max_value}
                            value={props.value}
                            onChange={props.onChange}
                        />

                        <div className="input-group-prepend">
                            <span className="input-group-text h-100 px-3 rounded-0">kr</span>
                        </div>
                    </div>
                </div>

                {props.showInfoBox ? (
                    <div className="d-flex bg-lightGreen p-3 green-tips">
                        <FontAwesomeIcon icon="lightbulb" inverse="100%" className="bg-green p-2 rounded-5 infobox-icon" />
                        <div className="text-start ps-3">
                            {/* Title for infobox */}
                            <span className="fw-bold">{data.infobox.title}</span>
                            {/* Message for infobox */}
                            <span>{data.infobox.message}</span>
                        </div>
                    </div>
                ) : (
                    null
                )}
            </div>
        </div>
    )
}