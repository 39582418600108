import React from "react";
import Header from "../components/Header"
import ButtonGroup from "../components/ButtonGroup";
import ConcentModal from "../components/ConcentModal";


export default function Summary(props) {
    let totalPrice = 0;
    for (let i = 0; i < props.savings.length; i++) {
        totalPrice += parseInt(props.savings[i].price);
    }

    return (
        <div className="container">


            <Header title="Oppsummering" back="sparemalListe" />

            <div className="row justify-content-center">
                <div className="col col-lg-8 col-xl-6 col-md-8">
                    <h1 className="h1 text-center text-uppercase">
                        DINE ØNSKER <br className="header-br" /> FOR <span className={`greyAfter ${props.operatingSystem ? "spanForMac" : ""}`}>FREMTIDEN</span>
                    </h1>
                </div>
            </div>



            <div className="row justify-content-center">
                <div className="col col-lg-4 col-md-7">
                    <div className="text-center mt-4 mb-2">
                        <h5 className="h5 fw-bold text-uppercase">
                            YRKE og UTDANNING
                        </h5>
                    </div>
                    <div className="text-center">
                        <p className="mb-1">Yrke: <h5 className="fw-bold h5 d-inline">{props.jobTitle}</h5></p>
                        <p className="mb-1">Årslønn: <h5 className="fw-bold h5 d-inline">{parseInt(props.costSalary).toLocaleString("no")},-</h5></p>
                        {
                            props.studentLoan && sessionStorage.getItem('studentLoan') != 0 ?
                                <p className="mb-1">Studielån: <h5 className="fw-bold h5 d-inline">{props.studentLoan.toLocaleString('no')},- </h5><h5 className="h5 d-inline">({props.sliderValue} år)</h5></p>
                                : <p className="mb-1">Studielån: ingen</p>
                        }
                    </div>

                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col col-lg-4 col-md-7">
                    <hr />
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col col-lg-4 col-md-7">
                    <div className="text-center">
                        <h5 className="h5 fw-bold text-uppercase mb-2">
                            DINE SPAREMÅL
                        </h5>
                    </div>
                    <div className="text-center">
                        <ul className="p-0 mb-0 d-flex flex-wrap justify-content-center">{props.savings.map((item) => (
                            <li className="list-unstyled summaryListItem me-3">{item.title} </li>
                        ))}</ul>
                        <h5 className="fw-bold h5">{totalPrice.toLocaleString('no')},-</h5>
                    </div>
                </div>
            </div>

            <ButtonGroup
                link1="sparemalListe"
                link2="ferdig"
            />

            <ConcentModal />

        </div>



    )
}