import React, { useState } from "react";

export default function CostFormLeft(props) {

    const { data, placeholder } = props;

    
    return (
        <div className="row justify-content-center">
            <div className="col col-lg-6 col-xl-6 col-md-8">
                <div className="">
                    <h6 className="h6 d-block mb-2 fw-bold text-start">
                        {data.title}
                    </h6>
                    <p className="text-start">{data.subtitle}</p>

                    <div className="input-group mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={data.placeholder ? data.placeholder : placeholder}
                            aria-label="Amount"
                            min={data.min_value}
                            max={data.max_value}
                            value={props.value}
                            onChange={props.onChange}
                            disabled={props.disabled ? true : false}
                        />

                        <div className="input-group-prepend">
                            <span className="input-group-text h-100 px-3 rounded-0">kr</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}