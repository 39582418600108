import React from 'react';
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ButtonGroup = (props) => {

    return (
        <div className="row justify-content-center pb-5 mt-5 gx-2">
            <div className="col-6 col-lg-3 col-xl-2 col-md-4 col-sm-5">
                <div className="button-next text-center w-100">                                       
                    <Link to={`/${props.link1}`} className="d-flex align-items-center justify-content-center fw-semibold btn rounded-pill w-100 py-3 form_button__item form_button__item-grey">
                        <FontAwesomeIcon icon="fa-solid fa-arrow-left" size="xs" className="me-2" />
                        Tilbake
                    </Link>
                </div>                    
            </div>
            <div className="col-6 col-lg-3 col-xl-2 col-md-4 col-sm-5">                            
                <div className="button-next text-center w-100">                                       
                    <Link to={`/${props.link2}`} className="d-flex align-items-center justify-content-center text-white fw-semibold btn rounded-pill w-100 bg-green py-3 form_button__item form_button__item-green">
                        Videre
                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" size="xs" className="ms-2" />
                    </Link>    
                </div>
            </div>                        
        </div>        
    )
};

export default ButtonGroup;