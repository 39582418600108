import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import JobTitleForm from "./JobTitleForm"
import CostForm from './CostFrom';
import Range from './Range';


const EditEduPro = (props) => {

    return (
        <div className="container">

            <div className="row mb-3 justify-content-center">
                <div className="col-12 col-lg-6 col-md-4">
                    <h5 className="h5 text-center">
                        YRKE og UTDANNING
                    </h5>
                </div>
            </div>

            <div className="row mb-5 justify-content-center">
                <div className="col-6 col-lg-3 col-xl-2 col-md-4">
                    <div className="button-next text-center w-100">
                        <div onClick={() => props.handleBackRediger(false)} className="d-flex align-items-center justify-content-center text-base fw-semibold btn rounded-pill w-100 py-3 form_button__item form_button__item-grey">
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left" size="xs" className="me-2" />
                            Tilbake
                        </div>
                    </div>
                </div>
            </div>



            <JobTitleForm
                value={props.jobTitle}
                data={props.dataYrke.work_title}
                onChange={props.onChange}
                text="text-start"
            />

            <CostForm            
                value={props.formattedCostSalary}
                onChange={props.onChangeCostForm}
                data={props.dataYrke.start_salary}
            />

            <div className="row justify-content-center mt-5">
                <Range
                    data={props.dataUtdaning.studiear_slider}
                    onChange={props.onChangeEduSlider}
                    sliderValue={props.sliderValue}
                    className="position-relative"
                    object="år"
                    id="eduRange"
                    textCenter={props.dataUtdaning.studiear_slider.questionmark.show ? false : true}
                />
            </div>
        </div>
    )

}


export default EditEduPro;