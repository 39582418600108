import React from "react";
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default function ButtonNext(props) {
    return (
       
        <p className={`text-center`} style={{position: 'absolute', top: '1rem', right: '1rem', zIndex: '10'}}>                                       
            <Link to={`/${props.link}`} className={`d-flex align-items-center fw-bold text-decoration-none`}>
                {props.text ? props.text : 'Lukk'}
                <FontAwesomeIcon icon="fa-solid fa-xmark" size="1x" className="ms-1" />
            </Link>                    
        </p>
                   
    )
}