import React, { useState, useEffect } from "react";

import ButtonNext from "../components/ButtonNext";
import LinkNext from "../components/LinkNext";
import ConsumptionItem from "../components/ConsumptionItem";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/sharp-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

export default function ConsumptionCalculator(props) {
    const optionsData = props.data.forbrukskalkulator.options

    // State to hold the selected options with their amounts
    const [selectedOptions, setSelectedOptions] = useState(
        optionsData.reduce((acc, option) => {
            acc[option.id] = { ...option, amount: 0 };
            return acc;
        }, {})
    );

    // Function to update the total amount whenever the selectedOptions change
    useEffect(() => {
        const total = Object.values(selectedOptions).reduce(
            (acc, option) => acc + option.price_unit * option.amount,
            0
        );
        props.setTotalAmount(total);
    }, [selectedOptions]);

    // Function to handle increasing the amount of an option
    const handleIncrement = (id) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [id]: { ...prevOptions[id], amount: prevOptions[id].amount + 1 },
        }));
    };

    // Function to handle decreasing the amount of an option
    const handleDecrement = (id) => {
        setSelectedOptions((prevOptions) => {
            const prevAmount = prevOptions[id].amount;
            if (prevAmount > 0) {
                return {
                    ...prevOptions,
                    [id]: { ...prevOptions[id], amount: prevAmount - 1 },
                };
            }
            return prevOptions;
        });
    };

    // Function to handle changes in the input field
    const handleInputChange = (id, value) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [id]: { ...prevOptions[id], amount: isNaN(value) ? 0 : value },
        }));
    };

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <section className="consumptionBox bg-purple w-100 pt-5">
            <LinkNext link="kalkulator?scrollToCalculator2" >Lukk <FontAwesomeIcon icon="fa-solid fa-xmark" size="1x" className="ms-1" /></LinkNext>
            <div className="container">
                <div className="row justify-content-center text-center pt-5">
                    <div className="col col-lg-6 col-xl-6 col-md-8">
                        <p className="fw-bold">Kose- og sløsekalkulator</p>
                        <h1 className="h1">Hva bruker du penger på i løpet av   <span className={`pinkAfter ${props.operatingSystem ? "spanForMac" : ""}`}>en uke?</span></h1>
                    </div>
                </div>

                <div className="row justify-content-center mt-5 text-center">
                    <div className="col col-lg-6 col-xl-6 col-md-8">
                        <h5>Antall enheter</h5>
                    </div>
                </div>

                <ConsumptionItem
                    data={props.data}

                    selectedOptions={selectedOptions}
                    increase={handleIncrement}
                    decrease={handleDecrement}
                    handleInputChange={handleInputChange}
                />


                <div className="row justify-content-center">
                    <div className="col col-lg-5 col-xl-4 col-md-7 justify-content-center d-flex" style={{ paddingBottom: '4rem' }}>
                        <ButtonNext link="forbrukskalkulatorResultat" text="Se ditt resultat" />
                    </div>
                </div>
            </div>
        </section>
    )
}