import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ConcentModal from "../components/ConcentModal";

export default function Home(props) {
    return (
        <div className="page-home-background h-100">
            <div className="container h-100 page-home d-flex flex-column">
                <div className="row justify-content-center pt-2">
                    <div className="col-9 col-lg-4 col-md-5 mt-5">
                        <div className="header text-center">
                            <img src="images/logo.svg" alt="" />
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center mt-5 pt-5">
                    <div className="col-9 col-lg-4 col-md-5">
                        <div className="form text-center">
                            <h1 className="h1 text-uppercase ">
                                {props.data.home.title}
                            </h1>
                            <div className="form_text text mb-5 mt-3 text">
                                {props.data.home.text}
                            </div>
                            <div className="form_button">
                                <Link to="/yrke" className="d-flex align-items-center justify-content-center form_button__item text-white fw-semibold btn rounded-pill w-100 bg-darkBlue py-3">
                                    Start
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-right" size="xs" className="ms-2" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <ConcentModal />
            </div>
        </div>
    )
}