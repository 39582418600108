import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/sharp-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

const Range = (props) => {
    const { data } = props;
    const [blockPosition, setBlockPosition] = useState(0);
    const [blockPosition2, setBlockPosition2] = useState(0);

    useEffect(() => {
        const slider = document.getElementById(props.id);
        const block = document.getElementById(props.id + "Number");

        if (slider && block) {
            const sliderWidth = slider.offsetWidth;
            const sliderMax = parseInt(slider.max);
            const sliderMin = parseInt(slider.min);
            const blockWidth = block.offsetWidth;
            // const newPosition = (props.sliderValue / sliderMax) * (sliderWidth - blockWidth);
            const newPosition = ((props.sliderValue - sliderMin) / (sliderMax - sliderMin)) * (sliderWidth - blockWidth);
            /* console.log(sliderMax); */
            setBlockPosition(newPosition);
            /* console.log(block); */
        }

    }, [props.sliderValue, props.accOpen]);

    useEffect(() => {
        if (props.id2) {
            const slider = document.getElementById(props.id);
            const block = document.getElementById(props.id + "Number");

            const slider2 = document.getElementById(props.id2);
            const block2 = document.getElementById(props.id2 + "Number");

            if (slider && block) {
                const sliderWidth = slider.offsetWidth;
                const sliderMax = parseInt(slider.max);
                const sliderMin = parseInt(slider.min);
                const blockWidth2 = block2.offsetWidth;
                
                // const newPosition = (props.sliderValue / sliderMax) * (sliderWidth - blockWidth);
                const newPosition2 = ((props.sliderValue - sliderMin) / (sliderMax - sliderMin)) * (sliderWidth - blockWidth2);
                /* console.log(sliderMax); */
                setBlockPosition2(newPosition2);
                /* console.log(block); */
            }
        }


    }, [props.sliderValue, props.accOpen]);



    const [showInfo, setShowInfo] = useState(false);
    const [showInfoText, setShowInfoText] = useState('Mer info');

    const handleInfoClick = () => {

        if (showInfo) {
            setShowInfo(false);
            setShowInfoText('Mer info')
        } else {
            setShowInfo(true);
            setShowInfoText('Lukk')
        }
    }

    return (

        <div className="col col-lg-6 col-xl-6 col-md-8">

            <div className={`d-flex justify-content-${props.textCenter ? 'center' : 'between'}`}>
                <h6 className={`h6 fw-bold mb-2 text-${props.textCenter ? 'center' : 'start'}`}>
                    {props.title ? props.title : data.title}
                </h6>

                <div className="col-auto my-auto mb-2">
                    {props.data.questionmark.show ? (
                        <div className="d-flex merInfo" onClick={handleInfoClick}>
                            <p className="unbreakable me-1 mb-0 text-decoration-underline">{showInfoText}</p>
                            <FontAwesomeIcon icon="fa-solid fa-question" size="sm" className="rounded-5 p-1" />
                        </div>
                    ) : (
                        null
                    )}
                </div>
                {/* <h5>{parseInt(props.sliderValue).toLocaleString('no')} {props.object}</h5> */}
            </div>

            {showInfo ? (
                <div className="justify-content-center">
                    <div className="bg-white p-3 text-start infoBox mb-3 d-flex flex-column">
                        <span className="fw-bold">{props.data.questionmark.title}</span>
                        <p>{props.data.questionmark.text}</p>
                        <a href={props.data.questionmark.link.url} className="opacity-50 ms-auto" target='_blank'>{props.data.questionmark.link.title}<FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" size="s" className="ms-2" /></a>
                    </div>
                </div>
            ) : (
                null
            )}


            <p className={`mb-3 text-${props.textCenter ? 'center' : 'start'} `}>{data.message}</p>
            <input id={props.id}
                type="range"
                value={props.sliderValue}
                min={data.min_value}
                max={data.max_value}
                step={data.step ? data.step : 1}
                onChange={props.onChange}
                className="p-0 w-100 slider mb-1 win10-thumb"
                style={{ touchAction: 'pan-x' }}
            />
            <div id="output"></div>
            {props.accOpen === true || props.accOpen === undefined ?
                <h6 id={props.id + "Number"} className={`h6 fw-bold text-uppercase ${props.secondValue ? 'mb-0' : 'mb-5'} text-nowrap blockEdu`} style={{
                    marginLeft: blockPosition + "px", width: 'fit-content'
                }}>{parseInt(props.sliderValue).toLocaleString('no')} {props.object}</h6>
                : <h6 id={props.id + "Number"} className={`h6 fw-bold text-uppercase ${props.secondValue ? 'mb-0' : 'mb-5'} text-nowrap blockEdu`} style={{
                    marginLeft: blockPosition + "px"
                }}>{parseInt(props.sliderValue).toLocaleString('no')} {props.object}</h6>
            }

            {props.secondValue ? (
                <h6 id={props.id2 + "Number"} className='h6 bg-darkBlue text-white fw-bold text-uppercase text-center mb-5 text-nowrap blockEdu' style={{
                    marginLeft: blockPosition2 + "px", width: 'min-content', padding: '3px 3px 0 3px'
                }}>-{parseInt(props.secondValueProp).toLocaleString('no')},- mnd</h6>
            ) : (
                null
            )}


        </div>

    );
}

export default Range;