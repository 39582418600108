import React, { useState, useEffect } from "react";

import ButtonNext from "../components/ButtonNext";
import LinkNext from "../components/LinkNext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/sharp-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

export default function ConsumptionResult(props) {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <section className="consumptionBox bg-purple w-100 pt-5">
            <LinkNext link="kalkulator?scrollToCalculator2" >Lukk <FontAwesomeIcon icon="fa-solid fa-xmark" size="1x" className="ms-1" /></LinkNext>
            <div className="container">
                <div className="row justify-content-center text-center pt-5">
                    <div className="col col-lg-6 col-xl-6 col-md-8">
                        <p className="fw-bold">Kose- og sløsekalkulator</p>
                        <h1 className="h1">Hva bruker du penger på i løpet av <span className={`pinkAfter ${props.operatingSystem ? "spanForMac" : ""}`}>en uke?</span></h1>
                    </div>
                </div>

                <div className="row justify-content-center mt-5 text-center">
                    <div className="col col-lg-6 col-xl-6 col-md-8">
                        <h5>Ditt månedlige forbruk</h5>
                        <h2>{Math.round(props.totalAmount / 7 * 30).toLocaleString('no')} Kr/mnd</h2>
                    </div>
                </div>

                <div className="row justify-content-center mt-4 text-center mx-5 mb-5">
                    <div className="col col-lg-6 col-xl-6 col-md-8">
                        <h5>I året</h5>
                        <h1 className="h1"><span className={`pinkAfter ${props.operatingSystem ? "spanForMac" : ""}`}>= {Math.round(props.totalAmount / 7 * 30 * 12).toLocaleString('no')},-</span></h1>
                        <p className="mt-2">Kanskje er det mye, kanskje passe?
                            Uansett er det viktig å være bevisst hva pengene dine går til.</p>
                    </div>

                </div>
                <div className="row justify-content-center">
                    <div className="col col-lg-5 col-xl-4 col-md-7 justify-content-center d-flex" style={{ paddingBottom: '4rem' }}>
                    <ButtonNext link="kalkulator?scrollToCalculator2" class="mt-auto" marginY="my-2" text="Lukk" />
                    </div>
                </div>
            </div>
        </section>
    )
}