import React, { useState } from 'react';
import SavingsForm from './SavingsForm';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

const NewSaving = (props) => {
    const handleSaveSavingsData = (enteredSavingsData) => {
        const savingsData = {
            id: Math.random().toString(),
            ...enteredSavingsData
        };

        props.onAddSavings(savingsData)
    };

    return (       
        <div className="new-savings d-flex justify-content-center">
            <FontAwesomeIcon 
                className="closeIcon bg-darkBlue rounded-5 p-3" 
                icon="fa-close" 
                inverse="100%" 
                size="2x"
                onClick={props.handleClose}
            ></FontAwesomeIcon>
            <SavingsForm 
                onSaveSavingsData={handleSaveSavingsData}
                handleClose={props.handleClose}
                data={props.data}
            />
        </div>
    );
};

export default NewSaving;