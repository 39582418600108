import React from "react";

export default function ProfessionTitle(props) {

    const { data } = props;

    const getTextAlign = props.text;

    return (
        <div className="row justify-content-center">
            <div className="col col-lg-5 col-xl-4 col-md-7">
                <div className="mb-4">
                    <h6 className={"h6 text-center d-block mb-2 fw-bold " + getTextAlign}>
                        {data.title}
                    </h6>
                    <input 
                        type="text" 
                        className="form-control" 
                        placeholder={data.placeholder} 
                        aria-label="" 
                        aria-describedby="basic-addon1" 
                        value={props.value}
                        onChange={props.onChange}
                        maxLength={40}
                    />
                </div>  
            </div>
        </div>
              
    )
}