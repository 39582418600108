import React, { useState, useEffect } from "react";
import Header from "../components/Header"
import CostForm from "../components/CostFrom";
import ButtonNext from "../components/ButtonNext";
import ConcentModal from "../components/ConcentModal";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

export default function Savings(props) {

    const [salaryFeedback, setSalaryFeedback] = useState('');


    const handleInputChange = (e) => {

        const existingIndex = props.savings.findIndex(item => item.id === 0);

        const inputVal = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters

        if (existingIndex !== -1) {
            const updatedSavings = [...props.savings];
            updatedSavings[existingIndex] = {
                "id": 0,
                "icon": "fa-house",
                "title": "Bolig (Egenkapital)",
                "price": (inputVal * 0.15),
                "checked": true,
                "showDelete": false
            };

            props.setSavings(updatedSavings);
            props.setCostHousing(e.target.value)

            if (!inputVal || isNaN(inputVal)) {
                props.setFormattedCostHousing('');
                props.setCostHousing(0)
            } else {
                const numericVal = parseInt(inputVal, 10);
                const formatted = numericVal.toLocaleString('no'); // Format number with thousand separator
                props.setFormattedCostHousing(formatted);
                props.setCostHousing(numericVal);
            }
        } else {
            const updatedSavings = [
                {
                    "id": 0,
                    "icon": "fa-house",
                    "title": "Bolig (Egenkapital)",
                    "price": (inputVal * 0.15),
                    "checked": true,
                    "showDelete": false
                },
                ...props.savings
            ];

            props.setSavings(updatedSavings);
            props.setCostHousing(e.target.value)

            if (!inputVal || isNaN(inputVal)) {
                props.setFormattedCostHousing('');
                props.setCostHousing(0)
            } else {
                const numericVal = parseInt(inputVal, 10);
                const formatted = numericVal.toLocaleString('no'); // Format number with thousand separator
                props.setFormattedCostHousing(formatted);
                props.setCostHousing(numericVal);
            }
        }
    }

    // const forFeedback = props.savings[0].price / 0.15;       

    // useEffect(() => {
    //     const { intervals } = salaryData['fremtid_sparemal_bolig']; // Accessing the intervals from the JSON data

    //     // Find the appropriate message based on the entered salary
    //     const findMessage = (salary) => {
    //       for (let i = 0; i < intervals.length; i++) {
    //         if (salary >= intervals[i].value) {
    //           return intervals[i].message;
    //         }
    //       }
    //       return ''; // Default empty message if no interval matches
    //     };

    //     const salaryFeedback = findMessage(forFeedback);

    //     setSalaryFeedback(salaryFeedback);
    //   }, [forFeedback]);



    return (
        <div className="container">

            <Header title="Sparemål" back="utdanning" forward="sparemalListe" showNext={props.costHousing != 0 ? true : false} />

            <div className="row justify-content-center">
                <div className="col col-lg-8 col-xl-6 col-md-6">
                    <h1 className="h1 text-center text-uppercase">
                        din <span className={`greyAfter ${props.operatingSystem ? "spanForMac" : ""}`}>første</span> <br className="header-br" /> bolig
                    </h1>

                    <div className="text-center mt-4">
                        {props.data.fremtid_sparemal_bolig.text}
                    </div>
                    <div className="text-center fw-bold mt-4 mb-5">
                        <a className="h5" href={props.data.fremtid_sparemal_bolig.link.url} rel="noreferrer" target="_blank">
                            {props.data.fremtid_sparemal_bolig.link.title}
                        </a>
                    </div>

                </div>
            </div>
            <CostForm
                value={props.formattedCostHousing}
                onChange={handleInputChange}
                data={props.data.fremtid_sparemal_bolig}
                showInfoBox={false}
            />

            <div className="row justify-content-center">
                {props.costHousing >= 100000 ? (
                    <div className="col col-md-7 col-lg-5 col-xl-4">
                        <div className="d-flex bg-lightGreen p-3 green-tips">
                            <FontAwesomeIcon icon="lightbulb" inverse="100%" className="bg-green p-2 rounded-5 infobox-icon" />
                            <div className="text-start ps-3">
                                <span>{props.data.fremtid_sparemal_bolig.infobox.message}</span><br />
                                <span className="fw-bold">15% av {props.formattedCostHousing} = <span className="text-decoration-underline">{Math.round((props.costHousing * 0.15)).toLocaleString('no')},-</span></span>
                            </div>
                        </div>
                    </div>
                ) : (
                    null
                )}
            </div>



            {/* {salaryFeedback ?
                <div className="row justify-content-center
                ">
                    <div className="col col-lg-5 col-xl-4 col-md-7">
                        <div className="salaryFeedbackBox px-3 py-2 bg-lightOrange">
                        {salaryFeedback}
                        </div>
                    </div>
                </div>
            : ''} */}

            <div className="row justify-content-center">
                <div className="col col-lg-5 col-xl-4 col-md-7 justify-content-center d-flex">
                    <ButtonNext
                        link="sparemalListe"
                        disabled={props.costHousing != 0 ? false : true}
                    />
                </div>
            </div>

            <ConcentModal />

        </div>
    )
}