import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import JobTitleForm from "../components/JobTitleForm";
import CostForm from "../components/CostFrom";
import ButtonNext from "../components/ButtonNext";
import ConcentModal from "../components/ConcentModal";

export default function Profession(props) {
    const [salaryFeedback, setSalaryFeedback] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);

    const handleJobChange = (e) => {
        props.setJobTitle(e.target.value);
    };

    const handleCostChange = (e) => {
        const inputVal = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters

        if (!inputVal || isNaN(inputVal)) {
            props.setFormattedCostSalary('');
            props.setCostSalary(0)
        } else {
            const numericVal = parseInt(inputVal, 10);
            const numericValMonth = parseInt(inputVal / 12, 10)
            const formatted = numericVal.toLocaleString('no'); // Format number with thousand separator
            const formattedMonth = numericValMonth.toLocaleString('no')
            props.setFormattedCostSalary(formatted);
            props.setCostSalary(numericVal);

            props.setFormattedCostSalaryMonth(formattedMonth);
            props.setCostSalaryMonth(Math.round(numericValMonth));
        }
    };

    useEffect(() => {
        const { intervals } = props.data['profession']['start_salary'];

        const findMessage = (salary) => {
            for (let i = 0; i < intervals.length; i++) {
                if (salary >= intervals[i].value) {
                    return intervals[i].message;
                }
            }
            return '';
        };

        const salaryFeedback = findMessage(props.costSalary);

        setSalaryFeedback(salaryFeedback);
    }, [props.costSalary]);

    useEffect(() => {
        setIsFormValid(!!props.jobTitle && !!props.costSalary);
    }, [props.jobTitle, props.costSalary]);


    return (
        <div className="container">
            <Header title="Yrke" back="" forward="utdanning" showNext={props.jobTitle && props.costSalary != 0 ? true : false} />
            <div className="row justify-content-center">
                <div className="col col-lg-8 col-xl-6 col-md-6">
                    <h1 className="h1 text-center">

                        HVA VIL DU <br className="header-br" />

                        <span className={`greyAfter ${props.operatingSystem ? "spanForMac" : ""}`}>JOBBE MED?</span>


                    </h1>
                    <div className="text-center mt-4">
                        Har du tenkt på hva du skal bli? <br className="header-br" />
                        Det finnes mange valg. Finn noe du tror du kan trives med.
                    </div>
                    <div className="text-center fw-bold mt-4 mb-5">
                        <a
                            className="h5"
                            href={props.data.profession.link.url}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {props.data.profession.link.title}
                        </a>
                    </div>
                </div>
            </div>

            <JobTitleForm
                value={props.jobTitle}
                onChange={handleJobChange}
                data={props.data.profession.work_title}
            />

            <CostForm
                value={props.formattedCostSalary}
                onChange={handleCostChange}
                data={props.data.profession.start_salary}
            />

            {salaryFeedback && (
                <div className="row justify-content-center">
                    <div className="col col-lg-5 col-xl-4 col-md-7">
                        <div className="salaryFeedbackBox px-3 py-2 bg-lightOrange">
                            {salaryFeedback}
                        </div>
                    </div>
                </div>
            )}
            <div className="row justify-content-center">
                <div className="col col-lg-5 col-xl-4 col-md-7 justify-content-center d-flex">
                    <ButtonNext
                        link={isFormValid ? 'utdanning' : 'utdanning'}
                        disabled={props.jobTitle && props.costSalary != 0 ? false : true}
                    />
                </div>
            </div>

            <ConcentModal />
        </div>
    );
}
