import React, { useState, useEffect } from 'react';

import OptionSmall from './OptionSmall';
import ProgressBar from './ProgressBar';
import ListSavings from './ListSavings';
import Gauge from './Gauge';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/sharp-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

const Feedback = (props) => {
    const [showTitle, setShowTitle] = useState(false);
    const [values, setValues] = useState(
        [
            (
                +props.rentalPrice +
                +props.costInsurance +
                +props.costPower +
                +props.costTv
            ),
            (
                (+props.activeTransportationOption ? +props.costCarExpense : +props.costCollective)
            ),
            (
                +props.costFood +
                +props.costGrocery +
                +props.costCare +
                +props.costGaming +
                +props.costActivities +
                +props.costClothes +
                +props.costFurniture
                /* +props.costHousehold */
            ),
            (
                (+props.activeTransportationOption ? +props.costCar + +props.studentLoanMonth : +props.studentLoanMonth)
            ),
            (
                +props.savingsNormal +
                +props.savingsBsu
            )
        ]);

    useEffect(() => {
        setValues([
            (
                +props.rentalPrice +
                +props.costInsurance +
                +props.costPower +
                +props.costTv
            ),
            (
                (+props.activeTransportationOption ? +props.costCarExpense : +props.costCollective)
            ),
            (
                +props.costFood +
                +props.costGrocery +
                +props.costCare +
                +props.costGaming +
                +props.costActivities +
                +props.costClothes +
                +props.costFurniture
                /* +props.costHousehold */
            ),
            (
                (+props.activeTransportationOption ? +props.costCar + +props.studentLoanMonth : +props.studentLoanMonth)
            ),
            (
                +props.savingsNormal +
                +props.savingsBsu
            )
        ])
    }, [props.endResult,
    props.income,
    props.rentalPrice,
    props.costInsurance,
    props.costPower,
    props.costTv,
    props.costCollective,
    props.costCar,
    props.activeTransportationOption,
    props.costCarExpense,
    props.costFood,
    props.costGrocery,
    props.costCare,
    props.costGaming,
    props.costActivities,
    props.costClothes,
    props.costFurniture,
    /* props.costHousehold, */
    props.savingsNormal,
    props.savingsBsu,
    props.preSavings,
    props.preBsu,
    props.studentLoanMonth])

    const gaugeValues = [
        +props.costFood,
        +props.costGrocery,
        +props.costClothes,
        +props.costCare,
        +props.costGaming,
        +props.costActivities,
        /* +props.costHousehold, */
        +props.costFurniture
    ]

    useEffect(() => {
        const findMessage = (item) => {
            const results = props.data.resultat.feedback.results;
            for (let i = 0; i < results.length; i++) {
                if (item >= parseInt(results[i].interval)) {
                    return results[i];
                }
            }
            return '';
        };

        const feedback = findMessage(props.savingsYear);

        props.setSavingsTitle(feedback.title);
        props.setSavingsMessage(feedback.message);
    }, [props.savingsYear]);

    const handleOptionActive = (event, index) => {
        props.setActiveResultOption(index);
        sessionStorage.setItem('activeResultOption', index);
    };



    const sumArray = (arr) => {
        let total = 0;
        for (let i = 0; i < arr.length; i++) {
            total += arr[i];
        }
        return total;
    }

    const headerBackgroundImg = () => {
        if (props.savingsYear >= 15) {
            return './images/element-rod.svg';
        } else if (props.savingsYear >= 10) {
            return './images/element-oransj.svg';
        } else {
            return './images/element-gronn.svg';
        }
    }

    const backToTop = () => {
        const resultTop = document.querySelector(".resultPage");
        resultTop.scrollTo({ top: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        // Logic to check if any items meet the condition for showing the title
        const anyItemMeetsCondition = gaugeValues.some((item, index) => {
            const category = props.data.kalkulator_forbruk.categories[index];
            return item >= category.interval[0].toMuchPrice;
        });

        setShowTitle(anyItemMeetsCondition);
    }, [gaugeValues]);


    // Updating result
    const getTotalSum = (items) => {
        return items.reduce((total, item) => {
            return item.checked ? total + item.price : total;
        }, 0);
    };

    const totalSum = getTotalSum(props.savingsList);
    const years = Math.floor(((totalSum - (+props.preBsu + +props.preSavings)) / (+props.savingsNormal + +props.savingsBsu)) / 12);
    const months = Math.floor(((totalSum - (+props.preBsu + +props.preSavings)) / (+props.savingsNormal + +props.savingsBsu)) % 12);

    props.setSavingsYear(Math.max(years, 0));
    props.setSavingsMonth(Math.max(months, 0));

    return (
        <>
            <div className={`container mt-sm-4`}>
                <div className="row justify-content-center text-start mb-1">
                    <div className={`col col-lg-6 col-xl-6 col-md-8 position-relative bg-lightGreen py-3 ${props.headerBackgroundColor()}`} style={{ overflow: 'hidden' }}>
                        <div className='mb-1'>Du når sparemålene dine om</div>
                        <h3 className='h3 mb-0'>{props.savingsYear} ÅR OG {props.savingsMonth} {props.savingsMonth == 1 ? 'MÅNED' : 'MÅNEDER'} </h3>
                        <img src={headerBackgroundImg()} alt="" style={{ position: 'absolute', top: 0, right: '44px', maxHeight: '100%', maxWidth: '50%', transform: 'translate(50%, 0)' }} />
                    </div>
                </div>
            </div>




            <div className="container">

                <OptionSmall
                    data={props.data.resultat}
                    handleOptionActive={handleOptionActive}
                    activeOption={props.activeResultOption}
                />

                {+props.activeResultOption ? '' :
                    <div className="row justify-content-center text-center mx-2 mb-5">
                        <div className="col col-lg-6 col-xl-6 col-md-8">
                            <h1 className='h1 mb-4'>{props.savingsTitle}</h1>
                            <p>{props.savingsMessage}</p>
                        </div>
                    </div>
                }


                {sessionStorage.getItem('activeResultOption') === '1' ?
                    <div className="row">
                        <div className="col-12 col-lg-6 offset-lg-3 col-xl-6 col-md-8 offset-md-2 text-center">
                            <h2>Lek med dine sparemål</h2>
                            <p>Se hvordan antall måneder og år endrer seg når du rydder opp i budsjettet og leker med dine sparemål</p>
                        </div>

                        <div className="col-12 col-lg-6 offset-lg-3 col-xl-6 col-md-8 offset-md-2">
                            <ListSavings
                                savingsList={props.savingsList}
                                setSavings={props.setSavings}
                                showDelete={false}
                                showCheckbox={true}

                                savingsYear={props.savingsYear}
                                setSavingsYear={props.setSavingsYear}
                                savingsMonth={props.savingsMonth}
                                setSavingsMonth={props.setSavingsMonth}

                                savingsNormal={props.savingsNormal}
                                savingsBsu={props.savingsBsu}

                                preBsu={props.preBsu}
                                preSavings={props.preSavings}
                            />
                        </div>
                    </div>
                    :
                    <div className="row pt-4">
                        <div className="col-12 col-lg-6 offset-lg-3 col-xl-6 col-md-8 offset-md-2">
                            <ProgressBar
                                data={props.data.resultat.categories}
                                values={values}
                                income={props.income}
                            />
                        </div>

                        <div className="col-12 col-lg-6 offset-lg-3 col-xl-6 col-md-8 offset-md-2 gx-5">

                            {showTitle ? (
                                <h1 className="text-center mb-5">Her bruker du mer i forhold til andre</h1>
                            ) : (
                                null
                            )}

                            {gaugeValues.map((item, index) => {
                                const category = props.data.kalkulator_forbruk.categories[index];
                                let backgroundColor = null;

                                for (const interval of category.interval) {
                                    if (item >= interval.toMuchPrice) {
                                        backgroundColor = interval.backgroundColor;
                                    } else {
                                        break; // Stop searching for background color if the condition is not met
                                    }
                                }

                                return (
                                    item >= category.interval[0].toMuchPrice ? (
                                        <Gauge
                                            /* itemValue={Math.round(item / sumArray(values) * 100)} */
                                            item={item}
                                            maxValue={category.max_value}
                                            title={category.title}
                                            feedback={category.interval[0].toMuchFeedback}
                                            backgroundColor={backgroundColor}
                                            operatingSystem={props.operatingSystem}
                                        />
                                    ) : null
                                );
                            })}

                            {props.costHousing >= props.data.fremtid_sparemal_bolig.toMuchPrice ? (
                                <Gauge
                                    item={+props.costHousing}
                                    maxValue={props.data.fremtid_sparemal_bolig.max_value}
                                    title="MIN FØRSTE BOLIG"
                                    feedback={props.data.fremtid_sparemal_bolig.toMuchFeedback}
                                />
                            ) : null}


                        </div>

                        <div className="col-12 text-center mt-5 mb-2 d-flex justify-content-center">
                            <div className="to-top" onClick={backToTop}>
                                <h6 className="text-small h6 fw-bold text-uppercase mb-0">
                                    <small> Til toppen</small>
                                </h6>
                                <FontAwesomeIcon icon="fa-sharp fa-solid fa-angle-up" size="2xl" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default Feedback;

/* 
    Mat og drikke,-
    Annen dagligvare,-
    Klær,-
    Personlig pleie,-
    spill og gaming,
    fritid,
    husholdsningsartikler,
    møbler og interiør
*/