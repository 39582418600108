import React, { useState, useEffect, useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import Range from '../components/Range';
import CostFormLeft from '../components/CostFormLeft';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMoneyBillWave, faLightbulb } from '@fortawesome/free-solid-svg-icons';

library.add(faMoneyBillWave, faLightbulb);

const Income = (props) => {
    const [accOpen, setAccOpen] = useState(false);

    const handleEntered = () => {
        setAccOpen(true);
    }

    // Handle slider change for tax rate
    const handleSliderChange = (event) => {
        event.preventDefault();
        const value = parseFloat(event.target.value);

        props.setTaxrateSliderValue(value);
    };

    useEffect(() => {
        // Calculate income based on tax rate and salary
        const income = (1 - props.taxrateSliderValue / 100) * (props.costSalary / 12);
        props.setIncome(Math.round(income));
    }, [props.taxrateSliderValue, props.costSalary, props.setIncome]);

    return (

        <Accordion.Item eventKey="0" className={props.showActive}>

            <div className="row justify-content-center accordion-header-style">
                <div className={props.expand}>
                    <Accordion.Header className='w-100'>
                        <div className="calc-list_header d-flex justify-content-between align-items-center w-100">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon="fa-solid fa-money-bill-wave" size="lg" style={{ color: "#142656", }} />
                                <label className="ms-3 calc-acc-label">Inntekter</label>
                            </div>
                            <label className="me-3 fw-bold">{props.income ? '+ ' : null} {props.income.toLocaleString('no')},-</label>
                        </div>
                    </Accordion.Header>
                </div>
            </div>

            <Accordion.Body in={accOpen}
                onEntered={handleEntered} className='pt-5 px-0'
            >
                <div className="scrolling-body">
                    {/* Cost form for salary */}
                    <CostFormLeft
                        value={props.formattedCostSalaryMonth}
                        data={props.data.kalkulator_inntekt.arbeidsinntekt}
                        disabled={true}
                    />
                    {/* Slider for tax rate */}



                    <div className="row justify-content-center mt-4">

                        {accOpen ?
                            <Range
                                data={props.data.kalkulator_inntekt.skattetrekk}
                                onChange={handleSliderChange}
                                sliderValue={props.taxrateSliderValue}
                                className="position-relative"
                                object="%"
                                accOpen={accOpen}
                                id="incomeRange"
                                id2="incomeRange2"
                                secondValue={true}
                                secondValueProp={props.costSalaryMonth * props.taxrateSliderValue / 100}
                            />
                            :
                            // default
                            <Range
                                data={props.data.kalkulator_inntekt.skattetrekk}
                                onChange={handleSliderChange}
                                sliderValue={props.taxrateSliderValue}
                                className="position-relative"
                                object="%"
                                accOpen={accOpen}
                                id="incomeRange"
                                id2="incomeRange2"
                                secondValue={true}
                                secondValueProp={props.costSalaryMonth * props.taxrateSliderValue / 100}
                            />
                        }
                    </div>

                    <div className="row justify-content-center mb-4">
                        <div className="col col-lg-6 col-xl-6 col-md-8">
                            <div className="d-flex bg-lightGreen p-3 green-tips">
                                <FontAwesomeIcon icon="lightbulb" inverse="100%" className="bg-green p-2 rounded-5 infobox-icon" />
                                <div className="text-start ps-3">
                                    {/* Title for infobox */}
                                    <span className="fw-bold">{props.data.kalkulator_inntekt.infobox.title}</span>
                                    {/* Message for infobox */}
                                    <span>{props.data.kalkulator_inntekt.infobox.message}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Accordion.Body>

        </Accordion.Item>
    );
};

export default Income;