import { func } from "prop-types";
import React from "react";
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

export default function Header(props) {


    function ShowRow ({item, icon, show}) {
        if(item === undefined || show === false) {

            return(
                <FontAwesomeIcon icon={icon} className="opacity-0" />
            )                        
        }
        return(
            <Link to={`/${item}`} className="header_row">
                <FontAwesomeIcon icon={icon} className="opacity-25" />
            </Link>
        )
    }
    
    return (

        <div className="row justify-content-center mb-5">
            <div className="col col-lg-4 col-md-5">
                <div className="header mt-5">
                    <div className="header_logo text-center">
                        <img src="images/logo-min.svg" alt=""/>
                    </div>
                    <div className="header_crumbs d-flex justify-content-between mt-4">
                        <ShowRow item={props.back} icon="fa-solid fa-angle-left" />                                                                                     
                        <div className="header_title">
                            <div className="text">
                                {props.title}
                            </div>
                        </div>  
                        <ShowRow item={props.forward} icon="fa-solid fa-angle-right" show={props.showNext} />                
                    </div>                                        
                </div>                
            </div>
        </div>
    )
}