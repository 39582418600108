import React from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
);

const Gauge = (props) => {
    const data = {
        labels: [''],
        datasets: [{
            label: 'Poll',
            data: [props.maxValue, props.item, props.maxValue - props.item],
            /* data: [props.item, props.maxValue - props.item], */
            backgroundColor: [props.backgroundColor ? props.backgroundColor : '#F48843', props.backgroundColor ? props.backgroundColor : '#F48843', '#D8D9DA'],
            borderColor: [props.backgroundColor ? props.backgroundColor : '#F48843', props.backgroundColor ? props.backgroundColor : '#F48843', '#D8D9DA'],
            circumference: 180,
            rotation: 270,
            cutout: "70%",
            needleValue: props.maxValue + props.item
        }]
    }

    const options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            },
        },
        aspectRatio: 1.45
    };

    const gaugeNeedle = {
        id: 'gaugeNeedle',
        afterDatasetsDraw(chart, args, plugins) {
            const { ctx, data } = chart;

            ctx.save();
            const xCenter = chart.getDatasetMeta(0).data[0].x;
            const yCenter = chart.getDatasetMeta(0).data[0].y;
            const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
            const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
            const widthSlice = outerRadius - innerRadius / 2;
            const angle = Math.PI / 180;

            const needleValue = data.datasets[0].needleValue;

            const dataTotal = data.datasets[0].data.reduce((a, b) => a + b, 0);
            const circumference = ((chart.getDatasetMeta(0).data[0].circumference / Math.PI) / data.datasets[0].data[0]) * needleValue;

            ctx.translate(xCenter, yCenter);
            ctx.rotate(Math.PI * (circumference + 1.5))

            // Needle
            ctx.beginPath();
            ctx.strokeStyle = '';
            ctx.moveTo(0 - 7, -10);
            ctx.lineTo(0, 0 - outerRadius);
            ctx.lineTo(0 + 7, -10);
            ctx.stroke();
            ctx.fill();

            // Dot
            ctx.beginPath();
            ctx.strokeStyle = 'black';
            ctx.fillStyle = 'white';
            ctx.lineWidth = 15
            ctx.arc(0, -7, 5, 0, angle * 360, false);
            ctx.stroke();
            ctx.fill();

            ctx.restore();
        }
    }

    return (
        <div className="row justify-content-center speedometer">
            <div className='speedometer_item' style={{ width: "60%" }}>
                <Doughnut
                    data={data}
                    options={options}
                    plugins={[gaugeNeedle]}
                />
            </div>
            <div>

                <h4 className="h4 text-center"><span className={`greyAfter ${props.operatingSystem ? "spanForMac" : ""}`}>{props.item.toLocaleString('no')},-</span></h4>
                <h4 className="h4 text-center">{props.title}</h4>

                <small className='d-inline-block lh-sm'>{props.feedback}</small>
                <hr className="my-5"></hr>
            </div>
        </div>
    );
};

export default Gauge;