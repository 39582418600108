import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/sharp-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

const ConsumptionItem = (props) => {

    return (
        <div className="row justify-content-center">
            <div className="col col-lg-6 col-xl-6 col-md-8">
                {props.data.forbrukskalkulator.options.map((item, index) => (
                    <div className="d-flex align-items-center justify-content-between mt-4">
                        <div className="text-start ps-3" style={{flex: 1}}>
                            <h6 className="mb-0 h6 fw-medium text-normal me-1" style={{ textTransform: "none" }}>{item.title}</h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{flex: 1}}>
                            <FontAwesomeIcon 
                                icon="fa-solid fa-minus" 
                                inverse="100" 
                                size="xs" 
                                className="bg-darkBlue consumptionIcon p-2 rounded-5" 
                                onClick={() => props.decrease(item.id)}
                            />
                            <input 
                                type="number" 
                                name="" 
                                id="" 
                                className="consumptionInput mx-3" 
                                value={props.selectedOptions[index].amount} 
                                onChange={(e) => props.handleInputChange(item.id, parseInt(e.target.value))} 
                            />
                            <FontAwesomeIcon 
                                icon="fa-soli fa-plus" 
                                inverse="100" 
                                size="xs" 
                                className="bg-darkBlue consumptionIcon p-2 rounded-5" 
                                onClick={() => props.increase(item.id)}
                            />
                        </div>
                        <div className="text-end pe-3" style={{flex: 1}}>
                            <p className="fw-bold mb-0">{item.price_unit} KR</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>        
    );
};

export default ConsumptionItem;