import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import CostForm from './CostFrom';
import ListSavings from './ListSavings';
import Modal from 'react-bootstrap/Modal';
import NewSaving from './NewSaving';


const EditListSaving = (props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleAddSavings = (savings) => {
        props.setSavings((prevSavings) => {
            return [...prevSavings, savings];
        });
    }

    const handleDelete = (id) => {
        const newSavings = props.savingsList.filter(item => item.id !== id);

        props.setSavings(newSavings)
    }

    return (
        <div className="container">

            <div className="row mb-4 justify-content-center">
                <div className="col-12 col-lg-6 col-md-4">
                    <h5 className="h5 text-center">
                        Dine sparemål
                    </h5>
                </div>
            </div>

            <div className="row mb-5 justify-content-center">
                <div className="col-6 col-lg-3 col-xl-2 col-md-4">
                    <div className="button-next text-center w-100">
                        <div onClick={() => props.handleBackRediger(false)} className="d-flex align-items-center justify-content-center text-base fw-semibold btn rounded-pill w-100 py-3 form_button__item form_button__item-grey">
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left" size="xs" className="me-2" />
                            Tilbake
                        </div>
                    </div>
                </div>
            </div>


            <CostForm
                value={props.formattedCostHousing}
                data={props.dataBolig}                
                onChange={props.handleInputChange}
            />

            <div className="row justify-content-center mt-4">
                <div className="col col-lg-6 col-xl-6 col-md-8">
                    <div className="pb-3 d-flex justify-content-between align-items-center border-bottom">
                        <h5 className="h5 fw-bold">DINE SPAREMÅL</h5>
                        <FontAwesomeIcon
                            icon="fa-plus"
                            inverse="100%"
                            className="bg-darkBlue p-2 rounded-5 addSavingsIcon"
                            onClick={handleShow}
                        ></FontAwesomeIcon>
                    </div>

                    <ListSavings
                        savingsList={props.savingsList}
                        handleDelete={handleDelete}
                        showDelete={true}
                    />
                </div>


            </div>

            {/* New item Overlay */}
            <Modal show={show} onHide={handleClose} fullscreen={true}>
                <Modal.Body>
                    <NewSaving
                        onAddSavings={handleAddSavings}
                        handleClose={handleClose}
                        data={props.dataList.lag_et_sparemal}
                    />
                </Modal.Body>
            </Modal>


        </div>
    )

}


export default EditListSaving;