import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import Option from './Option';
import Range from './Range';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCarSide, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import OptionSmall from './OptionSmall';

const TransportationExpenses = (props) => {

    const [accOpen, setAccOpen] = useState(false);

    const handleEntered = () => {
        setAccOpen(true);
    }

    const handleOptionActive = (event, index) => {
        props.setActiveTransportationOption(index);
        sessionStorage.setItem('activeTransportationOption', index);
    };

    const handleOptionActiveExpense = (event, index) => {
        props.setActiveCarExpenseOption(index);
        props.setShowCarExpenseContent(1);

        sessionStorage.setItem('activeCarExpenseOption', index);
        sessionStorage.setItem('showCarExpenseContent', 1);

        const costCarExpense =
            index === 0
                ? props.data.kalkulator_transportutgifter.kostnader_type_bil.price_electric
                : props.data.kalkulator_transportutgifter.kostnader_type_bil.price_fuel;

        props.setCostCarExpense(costCarExpense);
        props.setCarExpenseSliderValue(costCarExpense);
    };

    // Handle slider change for tax rate
    const handleSliderChange = (event) => {
        event.preventDefault();
        const value = parseFloat(event.target.value);

        props.setCollectiveSliderValue(value);

    };

    const handleSliderChangeOwnCar = (event) => {
        event.preventDefault();
        const value = parseFloat(event.target.value);
        props.setCarSliderValue(value);
    };

    const handleSliderChangeEngine = (event) => {
        event.preventDefault();
        const value = parseFloat(event.target.value);
        props.setCarExpenseSliderValue(value);
    }


    useEffect(() => {
        props.setCostCollective(props.collectiveSliderValue);
    }, [props.collectiveSliderValue, props.setCostCollective]);

    useEffect(() => {
        const costCar = Math.floor((props.carSliderValue / 5 / 12) * (1 + props.data.kalkulator_lan_og_gjeld.billan.rate / 100));
        props.setCostCar(costCar);
    }, [props.carSliderValue, props.setCostCar]);

    useEffect(() => {
        props.setCostCarExpense(props.carExpenseSliderValue);
    }, [props.carExpenseSliderValue, props.setCostCarExpense]);

    return (
        <Accordion.Item eventKey="2" className={props.showActive}>

            <div className="row justify-content-center accordion-header-style">
                <div className={props.expand}>
                    <Accordion.Header className='w-100'>
                        <div className="calc-list_header d-flex justify-content-between align-items-center w-100">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon="car-side" size="lg" style={{ color: "#142656", }} />
                                <label className="ms-3 calc-acc-label">Transportutgifter</label>
                            </div>
                            <label className="ms-auto me-3 fw-bold">
                                {(props.activeTransportationOption && props.costCarExpense > 0) || (props.costCollective && !props.activeTransportationOption)
                                    ? '- '
                                    : null}
                                {parseInt(props.activeTransportationOption) ? parseInt(props.costCarExpense).toLocaleString('no') : parseInt(props.costCollective).toLocaleString('no')},-
                            </label>
                        </div>
                    </Accordion.Header>
                </div>
            </div>

            <Accordion.Body in={accOpen}
                onEntered={handleEntered} className='pt-5 px-0'>
                <OptionSmall
                    data={props.data.kalkulator_transportutgifter.choice}
                    handleOptionActive={handleOptionActive}
                    activeOption={props.activeTransportationOption}
                />

                {parseInt(props.activeTransportationOption) ? (
                    <div>
                        <div className="row justify-content-center">

                            {/* <RangeLeft
                                data={props.data.kalkulator_transportutgifter.pris_bil}
                                onChange={event => props.setCarSliderValue(parseFloat(event.target.value))}
                                sliderValue={props.carSliderValue}
                                object="kr"
                            /> */}
                            {accOpen ? (
                                <Range
                                    data={props.data.kalkulator_transportutgifter.pris_bil}
                                    onChange={handleSliderChangeOwnCar}
                                    sliderValue={props.carSliderValue}
                                    className="position-relative"
                                    object="kr"
                                    id="ownCarRange"
                                    id2="ownCarRange2"
                                    accOpen={accOpen}
                                    secondValue={true}
                                    secondValueProp={props.costCar}
                                />
                            ) : (
                                <Range
                                    data={props.data.kalkulator_transportutgifter.pris_bil}
                                    onChange={handleSliderChangeOwnCar}
                                    sliderValue={props.carSliderValue}
                                    className="position-relative"
                                    object="kr"
                                    id="ownCarRange"
                                    id2="ownCarRange2"
                                    accOpen={accOpen}
                                    secondValue={true}
                                    secondValueProp={props.costCar}
                                />
                            )}

                        </div>
                        <div className="row justify-content-center">
                            <div className="col col-lg-6 col-xl-6 col-md-8">
                                <div className="d-flex bg-lightGreen p-3 green-tips">
                                    <FontAwesomeIcon icon="lightbulb" inverse="100%" className="bg-green p-2 rounded-5 infobox-icon" />
                                    <div className="text-start ps-3">

                                        {/* Message for infobox */}
                                        <span>{props.data.kalkulator_transportutgifter.pris_bil.infobox.message}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col col-lg-6 col-xl-6 col-md-8">
                                <hr className="my-5"></hr>
                            </div>
                        </div>
                        

                            <Option
                                data={props.data.kalkulator_transportutgifter.kostnader_type_bil}
                                handleOptionActive={handleOptionActiveExpense}
                                activeOption={props.activeCarExpenseOption}
                            />
                            <div className="row justify-content-center">
                                <div className="col col-lg-6 col-xl-6 col-md-8 mt-3">
                                    {props.data.kalkulator_transportutgifter.kostnader_type_bil.message}
                                </div>
                            </div>

                        {sessionStorage.getItem('showCarExpenseContent') === '1' ? (

                            <div>

                                <div className="row justify-content-center">
                                    <div className="col col-lg-6 col-xl-6 col-md-8">
                                        <hr className="my-5"></hr>
                                    </div>
                                </div>

                                <div className="row justify-content-center">

                                    {accOpen ? (
                                        <Range
                                            data={props.data.kalkulator_transportutgifter.andre_kostnader}
                                            onChange={handleSliderChangeEngine}
                                            sliderValue={props.carExpenseSliderValue}
                                            className="test2 position-relative"
                                            object="kr"
                                            id="engineCar"
                                            accOpen={accOpen}
                                            title={sessionStorage.getItem('activeCarExpenseOption') === '1' ? 'Fossilbil' : 'Elbil'}
                                        />
                                    ) : (
                                        <Range
                                            data={props.data.kalkulator_transportutgifter.andre_kostnader}
                                            onChange={handleSliderChangeEngine}
                                            sliderValue={props.carExpenseSliderValue}
                                            className="test2 position-relative"
                                            object="kr"
                                            id="engineCar2"
                                            accOpen={accOpen}
                                        />
                                    )}


                                    {/* <RangeLeft
                                        data={props.data.kalkulator_transportutgifter.andre_kostnader}
                                        onChange={event => props.setCarExpenseSliderValue(parseFloat(event.target.value))}
                                        sliderValue={props.carExpenseSliderValue}
                                        object="kr"
                                        overwriteTitle={props.activeCarExpenseOption ? 'Fosilbil' : 'Elbil'}
                                    /> */}
                                </div>
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <div className="row justify-content-center text-center">

                        {accOpen ?
                            <Range
                                data={props.data.kalkulator_transportutgifter.utgifter_kollektivt}
                                onChange={handleSliderChange}
                                sliderValue={props.collectiveSliderValue}
                                className="position-relative"
                                object="kr"
                                id="collectiveCar"
                                accOpen={accOpen}
                            />
                            :
                            <Range
                                data={props.data.kalkulator_transportutgifter.andre_kostnader}
                                onChange={handleSliderChange}
                                sliderValue="850"
                                className="position-relative"
                                object="kr"
                                id="collectiveCar"
                                accOpen={accOpen}
                            />
                        }
                        {/* <RangeLeft
                                data={props.data.kalkulator_transportutgifter.utgifter_kollektivt}
                                onChange={event => props.setCollectiveSliderValue(parseFloat(event.target.value))}
                                sliderValue={props.collectiveSliderValue}
                                object="kr"
                            /> */}

                        <div className="row justify-content-center">
                            <div className="col col-lg-6 col-xl-6 col-md-8">
                                <div className="d-flex bg-lightGreen p-3 green-tips">
                                    <FontAwesomeIcon icon="lightbulb" inverse="100%" className="bg-green p-2 rounded-5 infobox-icon" />
                                    <div className="text-start ps-3">

                                        {/* Message for infobox */}
                                        <span>{props.data.kalkulator_transportutgifter.utgifter_kollektivt.infobox}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Accordion.Body>
        </Accordion.Item>
    );
};

export default TransportationExpenses;
