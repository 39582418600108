import React, { useState } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/sharp-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

const BetweenScreen = (props) => {

    return (
        <section className="betweenScreen bg-darkBlue text-white">
            <div className="container d-flex flex-column">
                <div className="row justify-content-center pt-2">
                    <div className="col-9 col-lg-4 col-md-5 mt-5">
                        <div className="header text-center d-flex align-items-center justify-content-center">
                            <Link to="/oppsummering">
                                <p className="text-white mb-0 betweenScreenBack fw-semibold text-decoration-none position-relative">
                                    Tilbake
                                    <FontAwesomeIcon icon="fa solid fa-arrow-left" inverse="true" className="position-absolute" style={{ top: "50%", left: "-16px", transform: "translateY(-50%)", fontSize: "0.8rem", }} />
                                </p>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center mt-5">
                    <div className="col-9 col-lg-4 col-md-5">
                        <div className="form text-center d-flex flex-column">
                            <FontAwesomeIcon icon="fa-sharp fa-solid fa-bullseye-arrow" size="2x" className="mb-4" style={{ color: "#F6AA51" }} />
                            <h1 className="h1 text-uppercase text-white">
                                Sett opp ditt budsjett
                            </h1>
                            <div className="form_text text mb-5 mt-3 text">
                                Tenk deg at du er ferdig med skolegang/utdanning, har startet som <span className={`greyAfter text-white ${props.operatingSystem ? "spanForMac" : ""}`}>{props.jobTitle}</span>, og tjener <span className="text-white">{parseInt(props.costSalary).toLocaleString("no")},- i året.</span>
                            </div>
                            <div className="mt-5 pt-5 mb-5">
                                <p className="fw-semibold text-white">Hvordan kan du nå sparemålene dine?</p>
                                <Link to="/kalkulator" className="d-flex align-items-center justify-content-center form_button__item text-black fw-semibold btn rounded-pill w-100 bg-green py-3 betweenScreenButton">
                                    Start budsjettkalkulatoren
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/*  <ConcentModal /> */}
            </div>
        </section>
    );
};

export default BetweenScreen;