import React, { useState, useEffect } from "react";
import axios from "axios";

import Config from "./Config";

export default function App() {
    const [configData, setConfigData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const url = (`${window.location.origin}/files/config/config.json`);

        // Hent konfigurasjonsdataen når komponenten blir montert
        axios
            .get(url)
            .then(response => {
                setConfigData(response.data);
                setLoading(false); // Når dataen er lastet inn, sett loading til false
            })
            .catch(error => {
                console.error('Feil ved henting av konfigurasjonsdata:', error);
                setLoading(false); // Hvis det oppstår feil, sett loading til false
            });
    }, []);

    // Render loading indicator or your main app content based on 'loading' state
    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Config
            configData={configData}
        />
    )
}
