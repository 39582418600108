import React from "react";
import Feedback from "../components/Feedback";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/sharp-solid-svg-icons';

export default function Result(props) {

    return (
        <div className="resultPage">

            <footer className="calc-footer position-fixed w-100 top-0">
                <div className="bg-white calculator-footer">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 p-4 d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <div className="calculator-icon p-3 rounded-5 me-2">
                                        <FontAwesomeIcon icon="fa-sharp fa-solid fa-calculator" size="xl" className="calculator" />
                                    </div>
                                    <div className="fw-bold ms-2" style={{ marginTop: "10px" }}>Til overs:</div>
                                </div>
                                <h5 className="text-end fw-normal mb-0 h5 total" style={{ marginTop: "4px" }}>
                                    {props.endResult.toLocaleString('no')},-
                                </h5>
                            </div>
                        </div>            
                    </div>
                </div>
                    
            </footer>

            <Feedback
                savingsList={props.savingsList}
                setSavings={props.setSavings}
                costHousing={props.costHousing}

                savingsYear={props.savingsYear}
                setSavingsYear={props.setSavingsYear}
                savingsMonth={props.savingsMonth}
                setSavingsMonth={props.setSavingsMonth}

                savingsTitle={props.savingsTitle}
                setSavingsTitle={props.setSavingsTitle}
                savingsMessage={props.savingsMessage}
                setSavingsMessage={props.setSavingsMessage}

                activeResultOption={props.activeResultOption}
                setActiveResultOption={props.setActiveResultOption}

                rentalPrice={props.rentalPrice}
                costInsurance={props.costInsurance}
                costPower={props.costPower}
                costTv={props.costTv}

                activeTransportationOption={props.activeTransportationOption}
                costCarExpense={props.costCarExpense}
                costCollective={props.costCollective}

                costFood={props.costFood}
                costGrocery={props.costGrocery}
                costCare={props.costCare}
                costGaming={props.costGaming}
                costActivities={props.costActivities}
                costClothes={props.costClothes}
                costFurniture={props.costFurniture}
                /* costHousehold={props.costHousehold} */

                costCar={props.costCar}
                studentLoanMonth={props.studentLoanMonth}

                savingsNormal={props.savingsNormal}
                savingsBsu={props.savingsBsu}

                preBsu={props.preBsu}
                preSavings={props.preSavings}
                operatingSystem={props.operatingSystem}
            />
        </div>
    )
}