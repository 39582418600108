import React from 'react';

const ProgressBar = (props) => {
    const getMessage = (category, value) => {
        const messages = category.messages;
        for (let i = 0; i < messages.length; i++) {
            if (value >= messages[i].interval) {
                return messages[i].message
            }
        }
        return '';
    };

    const getColor = (category, value) => {
        const messages = category.messages;
        for (let i = 0; i < messages.length; i++) {
            if (value >= messages[i].interval) {
                return messages[i].color;
            }
        }
        return '';
    };

    const sumArray = (arr) => {
        let total = 0;
        for (let i = 0; i < arr.length; i++) {
            total += arr[i];
        }
        return total;
    }

    return (
        <div>
            {props.data.map((item, index) => (
                <div className="" key={index}>                    
                    <div className="d-flex justify-content-between">
                        <h4 className="h4">{item.title}: {Math.round((props.values[index] / props.income) * 100)} %</h4>
                        <span className="fw-semibold">{(props.values[index]).toLocaleString('no')},-</span>
                    </div>
                    <div className="mb-3">
                        <div className="progress">
                            <div
                                className={`progress-bar ${getColor(item, (props.values[index] / props.income) * 100)}`}
                                role="progressbar"
                                style={{ width: (props.values[index] / props.income) * 100 + '%' }}
                                aria-valuenow={item.width}
                                aria-valuemin={item.min_value}
                                aria-valuemax={item.max_value}
                            ></div>
                        </div>
                    </div>
                    <div className="">
                        <small className="d-inline-block lh-sm">{getMessage(item, (props.values[index] / props.income) * 100)}</small>
                        <hr className="my-5"></hr>
                    </div>
                </div>

            ))}
        </div>
    );
};

export default ProgressBar;