import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/sharp-solid-svg-icons';
import Feedback from "../components/Feedback";

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

const ConcentModal = () => {
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = (event) => {
        event.preventDefault()
        setShowModal(false);
    }
    const handleShowModal = () => setShowModal(true);

    return (
        <>
            <div className="row mt-auto pt-4 pb-5 justify-content-center text-center">
                <div className="col d-flex flex-column">
                    <div className="mb-2">
                        <img src="images/logo-public.svg" alt="" style={{ width: '60px', height: 'auto', opacity: '30%' }} />
                    </div>
                    <div>
                        <a className="d-inline-block me-3" style={{ width: 'auto', opacity: '60%', cursor: 'pointer', textDecoration: 'underline' }} href="https://www.ue.no/okonomin" target="_blank">Om Økonomin</a>
                        <a className="d-inline-block" style={{ width: 'auto', opacity: '60%', cursor: 'pointer', textDecoration: 'underline' }} onClick={handleShowModal} >Personvern</a>
                    </div>
                </div>




            </div>

            <Modal show={showModal} onHide={handleShowModal} fullscreen={true}>
                <Modal.Body>
                    <div className="new-savings d-flex justify-content-center">
                        <FontAwesomeIcon
                            className="closeIcon bg-darkBlue rounded-5 p-3"
                            icon="fa-close"
                            inverse="100%"
                            size="2x"
                            onClick={handleCloseModal}
                        ></FontAwesomeIcon>
                        <form>

                            <div className="new-savings__controls">

                                <div className="row justify-content-center">
                                    <div className="col-12 col-lg-6 col-xl-6 col-md-8">
                                        <h2 className="fw-bold text-center mb-3">
                                            Personvern og informasjonskapsler
                                        </h2>
                                    </div>

                                    <div className="row justify-content-center">
                                        <div className="col-12 col-lg-6 col-xl-6 col-md-8">
                                            <p>Tjenesten er utviklet og levert av Ungt Entreprenørskap Norge.</p>
                                        </div>
                                    </div>

                                    <div className="row justify-content-center">
                                        <div className="col-12 col-lg-6 col-xl-6 col-md-8">
                                            <h5>INFORMASJONSKAPSLER</h5>
                                            <p>Vi bruker Google Analytics for å samle inn informasjon om hvordan besøkende samhandler med nettstedet vårt. Dette verktøyet bruker informasjonskapsler for å samle inn standard internettlogginformasjon og besøksadferd i en anonym form. Den genererte informasjonen om bruken av nettstedet, inkludert din IP-adresse, sendes til Google og lagres på deres servere. Vi bruker denne informasjonen til å analysere nettstedstrafikk, forstå bruksmønstre og forbedre brukeropplevelsen. Google deler ikke din IP-adresse med oss. Du kan velge å deaktivere informasjonskapsler i nettleserinnstillingene dine eller velge bort Google Analytics ved å installere Google Analytics Opt-out Browser Add-on.</p>
                                        </div>
                                    </div>

                                    <div className="row justify-content-center">
                                        <div className="col-12 col-lg-6 col-xl-6 col-md-8">
                                            <h5>PERSONVERN</h5>
                                            <p>Tjenesten innhenter ikke personopplysninger. Om du likevel er interessert i å lese om hvordan UE behandler personopplysninger finner du dette på <a href="https://www.ue.no/personvern" target="_blank">ue.no/personvern</a></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row justify-content-center mb-3">
                                <div className="col col-lg-6 col-xl-6 col-md-8">
                                    <div className="new-savings__action text-center mt-3">
                                        <button
                                            className="btn bg-darkBlue text-white rounded-5 px-5 py-2 fw-bold"
                                            type="submit"
                                            onClick={handleCloseModal}
                                        >
                                            Lukk
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ConcentModal;