import React, { useState, useEffect } from "react";

import Header from "../components/Header"
import Form from "../components/Form";
import ButtonNext from "../components/ButtonNext";
import Option from "../components/Option";
import Range from "../components/Range";
import ConcentModal from "../components/ConcentModal";

export default function Education(props) {
    const handleSliderChange = (event) => {
        const value = parseFloat(event.target.value);

        props.setSliderValue(value);
    }

    const studieLan = props.sliderValue * props.data.education.studiear_slider.lan_per_ar;
    const studieLanMonth = parseInt(studieLan / 12 / 20) * (1 + (props.data.kalkulator_lan_og_gjeld.studielan.rate / 100));

    const handleOptionActive = (event, index, showSlider) => {
        props.setActiveOption(index);
        props.setShowSlider(showSlider);
        sessionStorage.setItem('activeOption', index);
        sessionStorage.setItem('showSlider', showSlider);

        if (index) {
            props.setStudentLoan(parseInt(props.data.education.studiear_slider.lan_per_ar) * +props.sliderValue);
        } else {
            props.setStudentLoan(0);
        }
    }

    useEffect(() => {
        if (props.showSlider === true) {
            props.setStudentLoan(studieLan.toLocaleString('no'));
            props.setStudentLoanMonth(parseInt(studieLanMonth, 10));
        } else {
            props.setStudentLoan(0);
            props.setStudentLoanMonth(0);
        }
    }, [props.sliderValue]);

    /* useEffect(() => {
        if (props.showSlider === true) {
            props.setSliderValue(3)
        } else {
            props.setSliderValue(0)
        }
    }, [props.activeOption]) */

    

    useEffect(() => {
        props.setActiveOption(parseInt(sessionStorage.getItem('activeOption')));
    }, []);

    return (
        <div className="container education-page">

            <Header title="Utdanning" back="yrke" forward="sparemal" showNext={props.activeOption == 1 || props.activeOption == 0 ? true : false} />
            <div className="row justify-content-center">
                <div className="col col-lg-6 col-xl-4 col-md-8">
                    <div className="text-center mt-4 px-5 mb-2">
                        Så kult at du vil bli
                    </div>
                    <h1 className="h1 text-center text-uppercase mb-5">
                        <span className={`greyAfter ${props.operatingSystem ? "spanForMac" : ""}`}>{props.jobTitle}</span>
                    </h1>
                </div>
            </div>


            {/* Selects */}

            <Option
                data={props.data.education}
                setShowSlider={props.setShowSlider}
                studentLoan={props.studentLoan}
                setStudentLoan={props.setStudentLoan}
                setEducationType={props.setEduactionType}
                activeOption={props.activeOption}
                setActiveOption={props.setActiveOption}
                handleOptionActive={handleOptionActive}
                textCenter={props.data.education.questionmark.show ? false : true}
            />


            {/* Content of Høyere Utdanning */}
            {props.showSlider ?
                <div className="row justify-content-center text-center mt-5">
                    <Range
                        data={props.data.education.studiear_slider}
                        onChange={handleSliderChange}
                        sliderValue={props.sliderValue}
                        className="position-relative"
                        object="år"
                        id="eduRange"
                        textCenter={props.data.education.studiear_slider.questionmark.show ? false : true}
                    />
                    <div className="col-12">
                        <h5 className="h5 fw-bold ">
                            {studieLan.toLocaleString('no')},- {props.data.education.studiear_slider.text}
                        </h5>
                    </div>
                </div> : null}

            <div className="row justify-content-center">
                <div className="col col-lg-5 col-xl-4 col-md-7 justify-content-center d-flex">
                    <ButtonNext
                        link="sparemal"
                        disabled={sessionStorage.getItem('activeOption') === '1' || sessionStorage.getItem('activeOption') === '0' ? false : true}
                    />
                </div>
            </div>

            <ConcentModal />

        </div>
    )
}