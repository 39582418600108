import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import ButtonNext from './ButtonNext';
import Modal from 'react-bootstrap/Modal';
import Range from './Range';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

const Dept = (props) => {
    const [accOpen, setAccOpen] = useState(false);

    const handleEntered = () => {
        setAccOpen(true);
    }

    const [showStudent, setShowStudent] = useState(false);
    const [showCar, setShowCar] = useState(false);

    const handleCloseStudent = (event) => {
        event.preventDefault();
        setShowStudent(false);
    }
    const handleShowStudent = () => setShowStudent(true);

    const handleCloseCar = (event) => {
        event.preventDefault();
        setShowCar(false);
    } 
    const handleShowCar = () => setShowCar(true);

    const handleSliderChange = (event) => {
        event.preventDefault();
        const value = parseFloat(event.target.value);

        props.setSliderValue(value);
    }

    const handleSliderChangeOwnCar = (event) => {
        event.preventDefault();
        const value = parseFloat(event.target.value);

        props.setCarSliderValue(value);
    };

    const getShowLoans = () => {
        if (parseInt(props.activeTransportationOption) && props.costCar > 0) {
            if (props.studentLoanMonth > 0 && sessionStorage.getItem('activeOption') != 0) {
                return (props.costCar + props.studentLoanMonth).toLocaleString('no');
            } else {
                return (props.costCar).toLocaleString('no');
            }
        } else {
            if (props.studentLoanMonth > 0 && sessionStorage.getItem('activeOption') != 0) {
                return (props.studentLoanMonth.toLocaleString('no'));
            } else {
                return 0;
            }
        }
    };

    useEffect(() => {
        if (sessionStorage.getItem('activeOption') == 0) {
            props.setSliderValue(0);
        }
    }, []);

    /* useEffect(() => {
        if (props.sliderValue > 0) {
            sessionStorage.setItem('activeOption', 1);
        } else {
            sessionStorage.setItem('activeOption', 0);
        }

    }, [props.sliderValue]) */

    return (
        <Accordion.Item eventKey="4" className={props.showActive}>

            <div className="row justify-content-center accordion-header-style">
                <div className={props.expand}>
                    <Accordion.Header className='w-100'>
                        <div className="calc-list_header d-flex justify-content-between align-items-center w-100">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon="fa-sharp fa-solid fa-building-columns" size="lg" style={{ color: "#142656", }} />
                                <label className="ms-3 calc-acc-label">Lån og gjeld</label>
                            </div>
                            <label className="ms-auto me-3 fw-bold">
                                {((props.costCar > 0) && props.activeTransportationOption === 1) || props.studentLoanMonth > 0 && sessionStorage.getItem('activeOption') != 0 ? '- ' : null}
                                {getShowLoans()}
                                ,-</label>
                        </div>
                    </Accordion.Header>
                </div>
            </div>


            <Accordion.Body in={accOpen} onEntered={handleEntered}>
                {props.sliderValue > 0 && sessionStorage.getItem('activeOption') != 0 ?
                    <div className="row justify-content-center inside-from mb-4 mt-3">
                        <div className="col-lg-6 col-xl-6 lan-og-gjeld-box pt-3 pb-4 px-4 bg-white">
                            <div className="d-flex justify-content-between">
                                <h5 className="h5 fw-bold text-uppercase pt-4 mt-2">
                                    {props.data.kalkulator_lan_og_gjeld.studielan.title}
                                </h5>
                                <h6 className="text-capitalize fw-normal mb-4 h6 me-1 editButton" onClick={handleShowStudent}>
                                    <FontAwesomeIcon icon="fa-sharp fa-solid fa-pen-to-square" className="" />
                                    Rediger
                                </h6>
                            </div>
                            <div className="text-start dept-rediger">

                                <p className="mb-4">{props.data.kalkulator_lan_og_gjeld.studielan.subtitle}</p>
                                <p className="mb-1">Lånesum: <span className="fw-bold">{props.studentLoan},-</span></p>
                                <p className="mb-1">Rente: <span className="fw-bold">{props.data.kalkulator_lan_og_gjeld.studielan.rate.toLocaleString('no')}%</span></p>

                            </div>
                            <h5 className='text-end fw-bold'>{props.studentLoanMonth.toLocaleString('no')},- MND</h5>
                        </div>
                    </div> : null
                }



                {parseInt(props.activeTransportationOption) && props.costCar > 0 ?
                    <div className="row justify-content-center inside-from mb-4">
                        <div className="col-lg-6 col-xl-6 lan-og-gjeld-box pt-3 pb-4 px-4 bg-white">
                            <div className="d-flex justify-content-between">
                                <h5 className="h5 fw-bold text-uppercase pt-4 mt-2">
                                    {props.data.kalkulator_lan_og_gjeld.billan.title}
                                </h5>
                                <h6 className="text-capitalize fw-normal mb-4 h6 me-1 editButton" onClick={handleShowCar}>
                                    <FontAwesomeIcon icon="fa-sharp fa-solid fa-pen-to-square editButton" className="" />
                                    Rediger
                                </h6>
                            </div>
                            <div className="text-start dept-rediger">

                                <p className="mb-4">{props.data.kalkulator_lan_og_gjeld.billan.subtitle}</p>
                                <p className="mb-1">Lånesum: <span className="fw-bold">{props.carSliderValue.toLocaleString('no')},-</span></p>
                                <p className="mb-1">Rente: <span className="fw-bold">{props.data.kalkulator_lan_og_gjeld.billan.rate.toLocaleString('no')}%</span></p>

                            </div>
                            <h5 className='text-end fw-bold'>{props.costCar.toLocaleString('no')},- MND</h5>
                        </div>
                    </div> : null
                }

                {parseInt(props.activeTransportationOption) == 0 && sessionStorage.getItem('activeOption') == 0 ? (
                    <div className="row justify-content-center">
                        <div className="col col-lg-6 col-xl-6 col-md-8">
                            <p>Du har ikke lagt til noe lån i budsjettet.</p>
                            <div className="d-flex bg-lightGreen p-3 green-tips mb-3">
                                <FontAwesomeIcon icon="lightbulb" inverse="100%" className="bg-green p-2 rounded-5 infobox-icon" />
                                <div className="text-start ps-3">
                                    {/* Title for infobox */}
                                    <span className="fw-bold">{props.data.kalkulator_lan_og_gjeld.ingen_lan.title}</span>
                                    {/* Message for infobox */}
                                    <span>{props.data.kalkulator_lan_og_gjeld.ingen_lan.message}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    null
                )
                }

                <div className="row justify-content-center">
                    <div className="col col-lg-6 col-xl-6 col-md-8">
                        <div className="preparedCalculatorBox bg-lightBlue py-4 px-4" ref={props.calculatorRef}>
                            <h2 className="h2">ER DU <span className={`blueAfter ${props.operatingSystem ? "spanForMac" : ""}`}>FORBEREDT?</span></h2>
                            <p>Uforutsette ting kan skje når du minst venter det, eller du kan ta impulsive og ugjennomtenkte valg.
                            </p>
                            <ButtonNext link="forberedtKalkulator" text="Se hva som kan skje" marginY="my-1"></ButtonNext>
                        </div>
                    </div>
                </div>
            </Accordion.Body>

            <Modal show={showStudent} onHide={handleCloseStudent} fullscreen={true}>
                <Modal.Body>
                    <div className="new-savings d-flex justify-content-center">
                        <FontAwesomeIcon
                            className="closeIcon bg-darkBlue rounded-5 p-3"
                            icon="fa-close"
                            inverse="100%"
                            size="2x"
                            onClick={handleCloseStudent}
                        ></FontAwesomeIcon>
                        <form>

                            <div className="new-savings__controls">

                                <div className="row justify-content-center">
                                    <div className="col col-lg-6 col-xl-6 col-md-8">

                                        <h2 className="fw-bold mb-5 text-center">
                                            Rediger studielån
                                            <hr className="my-3" />
                                        </h2>
                                    </div>
                                </div>

                                <div className="new-savings__control">
                                    <div className="row justify-content-center">
                                        {accOpen ? (
                                            <Range
                                                data={props.data.education.studiear_slider}
                                                onChange={handleSliderChange}
                                                sliderValue={props.sliderValue}
                                                className="position-relative"
                                                object="år"
                                                id="eduRange"
                                                text="text-center"
                                                accOpen={accOpen}
                                            />
                                        ) : (
                                            <Range
                                                data={props.data.education.studiear_slider}
                                                onChange={handleSliderChange}
                                                sliderValue={props.sliderValue}
                                                className="position-relative"
                                                object="år"
                                                id="eduRange"
                                                text="text-center"
                                                accOpen={accOpen}
                                            />
                                        )}

                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col col-lg-6 col-xl-6 col-md-8">
                                            <hr className="my-1" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col col-lg-6 col-xl-6 col-md-8">
                                        <div className="new-savings__control text-center mt-5">
                                            <p className="mb-0">Lånesum: <span className="fw-bold">{props.studentLoan},-</span></p>
                                            <p className="mb-1">Rente: <span className="fw-bold">{props.data.kalkulator_lan_og_gjeld.studielan.rate.toLocaleString('no')}%</span></p>
                                            <h3 className='fw-normal mt-2'>{props.studentLoanMonth.toLocaleString('no')},- MND</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col col-lg-6 col-xl-6 col-md-8">
                                    <div className="new-savings__action text-center mt-3">
                                        <button
                                            className="btn bg-darkBlue text-white rounded-5 px-5 py-2 fw-bold"
                                            type="submit"
                                            onClick={handleCloseStudent}
                                        >
                                            Lagre<FontAwesomeIcon icon="fa-sold fa-floppy-disk" size="sm" className="ms-2"></FontAwesomeIcon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showCar} onHide={handleCloseCar} fullscreen={true}>
                <Modal.Body>
                    <div className="new-savings d-flex justify-content-center">
                        <FontAwesomeIcon
                            className="closeIcon bg-darkBlue rounded-5 p-3"
                            icon="fa-close"
                            inverse="100%"
                            size="2x"
                            onClick={handleCloseCar}
                        ></FontAwesomeIcon>
                        <form>
                            <div className="new-savings__controls">
                                <div className="row justify-content-center">
                                    <div className="col col-lg-6 col-xl-6 col-md-8">
                                        <h2 className="fw-bold mb-5 text-center">
                                            Rediger Billån
                                            <hr className="my-3" />
                                        </h2>
                                    </div>
                                </div>
                                <div className="new-savings__control">
                                    <div className="row justify-content-center">
                                        {accOpen ? (
                                            <Range
                                                data={props.data.kalkulator_transportutgifter.pris_bil}
                                                onChange={handleSliderChangeOwnCar}
                                                sliderValue={props.carSliderValue}
                                                className="position-relative"
                                                object="kr"
                                                id="ownCarRange2"
                                                accOpen={accOpen}
                                            />
                                        ) : (
                                            <Range
                                                data={props.data.kalkulator_transportutgifter.pris_bil}
                                                onChange={handleSliderChangeOwnCar}
                                                sliderValue={props.carSliderValue}
                                                className="position-relative"
                                                object="kr"
                                                id="ownCarRange2"
                                                accOpen={accOpen}
                                            />
                                        )}

                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col col-lg-6 col-xl-6 col-md-8">
                                            <hr className="my-1" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col col-lg-6 col-xl-6 col-md-8">
                                        <div className="new-savings__control text-center mt-5">
                                            <p className="mb-0">Lånesum: <span className="fw-bold">{props.carSliderValue.toLocaleString('no')},-</span></p>
                                            <p className="mb-1">Rente: <span className="fw-bold">{props.data.kalkulator_lan_og_gjeld.billan.rate.toLocaleString('no')}%</span></p>
                                            <h3 className='fw-normal mt-2'>{props.costCar.toLocaleString('no')},- MND</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col col-lg-6 col-xl-6 col-md-8">
                                    <div className="new-savings__action text-center mt-3">
                                        <button
                                            className="btn bg-darkBlue text-white rounded-5 px-5 py-2 fw-bold"
                                            type="submit"
                                            onClick={handleCloseCar}
                                        >
                                            Lagre<FontAwesomeIcon icon="fa-sold fa-floppy-disk" size="sm" className="ms-2"></FontAwesomeIcon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </Accordion.Item>
    );
};

export default Dept;