import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, HashRouter, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Profession from "./pages/Profession";
import Education from "./pages/Education";
import Savings from "./pages/Savings";
import SavingsList from "./pages/SavingsList";
import Summary from "./pages/Summary";
import Calculator from "./pages/Calculator";
import PreparedCalculator from "./pages/PreparedCalculator";
import PreparedCalculator2 from "./pages/PreparedCalculator2";
import ConsumptionLoan from "./pages/ConsumptionLoan";
import Result from "./pages/Result";
import PreparedFeedback from "./pages/PreparedFeedback";
import ConsumptionCalculator from "./pages/ConsumptionCalculator";
import ConsumptionResult from "./pages/ConsumptionResult";


import { useTransition, animated } from 'react-spring';
import BetweenScreen from "./pages/BetweenScreen";


const Config = (data) => {
    const configData = data.configData

    // Custom hook to manage state and sessionStorage
    function useSessionStorageState(key, initialValue) {
        const [value, setValue] = useState(() => {
            const savedValue = sessionStorage.getItem(key);
            return savedValue ? savedValue : initialValue;
        });

        // This useEffect will handle state changes and sessionStorage updates
        useEffect(() => {
            if (typeof value === 'string') {
                sessionStorage.setItem(key, value);
            } else {
                sessionStorage.setItem(key, JSON.stringify(value));
            }
        }, [key, value]);

        return [value, setValue];
    }

    window.addEventListener("beforeunload", (ev) => {
        if (!ev.returnValue) {
            ev.returnValue = 'Are you sure you want to close?';
        }
    });

    /* ################################## Profession useState's ################################# */
    const [jobTitle, setJobTitle] = useSessionStorageState('jobTitle', '');
    const [costSalary, setCostSalary] = useSessionStorageState('costSalary', '');
    const [formattedCostSalary, setFormattedCostSalary] = useSessionStorageState('formattedCostSalary', '')
    const [costSalaryMonth, setCostSalaryMonth] = useSessionStorageState('costSalaryMonth', '');
    const [formattedCostSalaryMonth, setFormattedCostSalaryMonth] = useSessionStorageState('formattedCostSalaryMonth');


    /* ################################# Education useState's ###################################### */
    const [showSlider, setShowSlider] = useSessionStorageState('showSlider', false);
    const [sliderValue, setSliderValue] = useSessionStorageState('sliderValue', 3);
    const [activeOption, setActiveOption] = useSessionStorageState('activeOption', '');
    const [studentLoan, setStudentLoan] = useSessionStorageState('studentLoan', '');
    const [studentLoanMonth, setStudentLoanMonth] = useSessionStorageState('studentLoanMonth', '');


    /* ############################# SavingsList useState's ################################## */
    const savingsList = [
        {
            "id": 0,
            "icon": "fa-house",
            "title": "Bolig (Egenkapital)",
            "price": 0,
            "checked": true,
            "showDelete": false
        }
    ];

    const [savings, setSavings] = useState(() => {
        const savedSavings = sessionStorage.getItem("savings");
        return savedSavings ? JSON.parse(savedSavings) : savingsList;
    });

    useEffect(() => {
        sessionStorage.setItem("savings", JSON.stringify(savings));
    }, [savings]);

    const [costHousing, setCostHousing] = useSessionStorageState('costHousing', '');
    const [formattedCostHousing, setFormattedCostHousing] = useSessionStorageState('formattedCostHousing', '');


    /* ################################ Calculator ###########################################*/
    /* Income */
    const [income, setIncome] = useSessionStorageState('income', '');
    const [taxrate, setTaxrate] = useSessionStorageState('taxrate', configData.kalkulator_inntekt.skattetrekk.default);
    const [taxrateSliderValue, setTaxrateSliderValue] = useSessionStorageState('taxrateSliderValue', configData.kalkulator_inntekt.skattetrekk.default);


    /* Living Expenses */
    const [showLivingContent, setShowLivingContent] = useSessionStorageState('showLivingContent', 0);
    const [activeLivingOption, setActiveLivingOption] = useSessionStorageState('activeLivingOption', '');
    const [rentalPrice, setRentalPrice] = useSessionStorageState('rentalPrice', '');
    const [formattedRentalPrice, setFormattedRentalPrice] = useSessionStorageState('formattedRentalPrice', '');
    const [costInsurance, setCostInsurance] = useSessionStorageState('costInsurance', '');
    const [insuranceSliderValue, setInsuranceSliderValue] = useSessionStorageState('insuranceSliderValue', 0);
    const [costPower, setCostPower] = useSessionStorageState('costPower', '');
    const [powerSliderValue, setPowerSliderValue] = useSessionStorageState('powerSliderValue', 0);
    const [costTv, setCostTv] = useSessionStorageState('costTv', '');
    const [tvSliderValue, setTvSliderValue] = useSessionStorageState('tvSliderValue', 0);


    /* Transportation Expenses */
    const [showCarExpenseContent, setShowCarExpenseContent] = useSessionStorageState('showCarExpenseContent', 0);
    const [activeTransportationOption, setActiveTransportationOption] = useSessionStorageState('activeTransportationOption', 0);
    const [activeCarExpenseOption, setActiveCarExpenseOption] = useSessionStorageState('activeCarExpenseOption', '');
    const [costCollective, setCostCollective] = useSessionStorageState('costCollective', configData.kalkulator_transportutgifter.utgifter_kollektivt.default);
    const [collectiveSliderValue, setCollectiveSliderValue] = useSessionStorageState('collectiveSliderValue', configData.kalkulator_transportutgifter.utgifter_kollektivt.default);
    const [costCar, setCostCar] = useSessionStorageState('costCar', '');
    const [carSliderValue, setCarSliderValue] = useSessionStorageState('carSliderValue', 0);
    const [costCarExpense, setCostCarExpense] = useSessionStorageState('costCarExpense', 0);
    const [carExpenseSliderValue, setCarExpenseSliderValue] = useSessionStorageState('carExpensesSliderValue', 0);

    /* Consumptions Expenses */
    const [costFood, setCostFood] = useSessionStorageState('costFood', '');
    const [foodSliderValue, setFoodSliderValue] = useSessionStorageState('foodSliderValue', configData.kalkulator_forbruk.categories[0].default);
    const [costGrocery, setCostGrocery] = useSessionStorageState('costGrocery', '');
    const [grocerySliderValue, setGrocerySliderValue] = useSessionStorageState('grocerySliderValue', configData.kalkulator_forbruk.categories[1].default);
    const [costClothes, setCostClothes] = useSessionStorageState('costClothes', '');
    const [clothesSliderValue, setClothesSliderValue] = useSessionStorageState('clothesSliderValue', configData.kalkulator_forbruk.categories[2].default);
    const [costCare, setCostCare] = useSessionStorageState('costCare', '');
    const [careSliderValue, setCareSliderValue] = useSessionStorageState('careSliderValue', configData.kalkulator_forbruk.categories[3].default);
    const [costGaming, setCostGaming] = useSessionStorageState('costGaming', '');
    const [gamingSliderValue, setGamingSliderValue] = useSessionStorageState('gamingSliderValue', configData.kalkulator_forbruk.categories[4].default);
    const [costActivities, setCostActivities] = useSessionStorageState('costActivities', '');
    const [activitiesSliderValue, setActivitiesSliderValue] = useSessionStorageState('activitesSliderValue', configData.kalkulator_forbruk.categories[5].default);
    /*     const [costHousehold, setCostHousehold] = useSessionStorageState('costHousehold', '');
        const [householdSliderValue, setHouseholdSliderValue] = useSessionStorageState('householdSliderValue', 550); */
    const [costFurniture, setCostFurniture] = useSessionStorageState('costFurniture', '');
    const [furnitureSliderValue, setFurnitureSliderValue] = useSessionStorageState('furnitureSliderValue', configData.kalkulator_forbruk.categories[6].default);


    /* Loan And Depts */

    /* Savings */
    const [savingsNormal, setSavingsNormal] = useSessionStorageState('savingsNormal', '');
    const [formattedSavingsNormal, setFormattedSavingsNormal] = useSessionStorageState('formattedSavingsNormal', '');
    const [savingsBsu, setSavingsBsu] = useSessionStorageState('savingssBsu', '');
    const [savingsBsuSliderValue, setSavingsBsuSliderValue] = useSessionStorageState('savingsBsuSliderValue', configData.kalkulator_spare.manedlig_sparing_bsu.default);
    const [preSavings, setPreSavings] = useSessionStorageState('preSavings', '');
    const [formattedPreSavings, setFormattedPreSavings] = useSessionStorageState('formattedPreSavings', '');
    const [preBsu, setPreBsu] = useSessionStorageState('preBsu', '');
    const [formattedPreBsu, setFormattedPreBsu] = useSessionStorageState('formattedPreBsu', '');


    /* End Result */
    const [endResult, setEndResult] = useSessionStorageState('endResult', '');
    const [savingsYear, setSavingsYear] = useSessionStorageState('savingsYear', '');
    const [savingsMonth, setSavingsMonth] = useSessionStorageState('savingsMonth', '');
    const [savingsTitle, setSavingsTitle] = useSessionStorageState('savingsTitle', '');
    const [savingsMessage, setSavingsMessage] = useSessionStorageState('savingsMessage', '');
    const [activeResultOption, setActiveResultOption] = useSessionStorageState('activeResultOption', 0);
    const [activePreparedFeedback, setActivePreparedFeedback] = useSessionStorageState('activePreparedFeedback', '');
    const [consumptionItems, setConsumptionItems] = useSessionStorageState('consumptionItems', []);


    // State to keep track of the total amount spent
    const [totalAmount, setTotalAmount] = useSessionStorageState('totalAmount', 0);


    /* Pepared Calculator */
    const [activePreparedOption, setActivePreparedOption] = useState('');


    function getOperatingSystem() {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.includes("mac")) {
            return true;
        } else if (userAgent.includes("ios")) {
            return true;
        } else {
            return false;
        }
    }
    const operatingSystem = getOperatingSystem();

    return (
        <HashRouter>

            <Routes>

                <Route path="/" 
                
                    element={<Home 
                        data={configData}
                    />} 
                />

                <Route path="/yrke"

                    element={<Profession
                        jobTitle={jobTitle}
                        setJobTitle={setJobTitle}

                        costSalary={costSalary}
                        setCostSalary={setCostSalary}
                        formattedCostSalary={formattedCostSalary}
                        setFormattedCostSalary={setFormattedCostSalary}

                        costSalaryMonth={costSalaryMonth}
                        setCostSalaryMonth={setCostSalaryMonth}
                        formattedCostSalaryMonth={formattedCostSalaryMonth}
                        setFormattedCostSalaryMonth={setFormattedCostSalaryMonth}

                        data={configData}
                        operatingSystem={operatingSystem}
                    />}
                />

                <Route path="/utdanning"

                    element={<Education
                        data={configData}

                        jobTitle={jobTitle}
                        studentLoan={studentLoan}
                        setStudentLoan={setStudentLoan}
                        studentLoanMonth={studentLoanMonth}
                        setStudentLoanMonth={setStudentLoanMonth}
                        sliderValue={sliderValue}
                        setSliderValue={setSliderValue}
                        showSlider={showSlider}
                        setShowSlider={setShowSlider}
                        activeOption={activeOption}
                        setActiveOption={setActiveOption}
                        operatingSystem={operatingSystem}
                    />}

                />

                <Route path="/sparemal"

                    element={<Savings
                        savings={savings}
                        setSavings={setSavings}
                        data={configData}
                        setCostHousing={setCostHousing}
                        costHousing={costHousing}
                        formattedCostHousing={formattedCostHousing}
                        setFormattedCostHousing={setFormattedCostHousing}
                        operatingSystem={operatingSystem}
                    />}

                />

                <Route path="/sparemalListe"

                    element={<SavingsList
                        savingsList={savings}
                        setSavings={setSavings}
                        data={configData}
                        operatingSystem={operatingSystem}
                    />}

                />

                <Route path="/oppsummering"

                    element={<Summary
                        jobTitle={jobTitle}
                        costSalary={costSalary}
                        studentLoan={studentLoan}
                        sliderValue={sliderValue}
                        savings={savings}
                        operatingSystem={operatingSystem}
                        data={configData}
                    />}
                />

                <Route path="/ferdig"

                    element={<BetweenScreen
                        operatingSystem={operatingSystem}

                        jobTitle={jobTitle}
                        costSalary={costSalary}

                        data={configData}
                    />}

                />

                <Route path="/kalkulator"

                    element={<Calculator
                        data={configData}

                        savings={savings}
                        jobTitle={jobTitle}
                        setJobTitle={setJobTitle}
                        sliderValue={sliderValue}
                        setSliderValue={setSliderValue}
                        setStudentLoan={setStudentLoan}
                        studentLoanMonth={studentLoanMonth}
                        setStudentLoanMonth={setStudentLoanMonth}
                        showSlider={showSlider}
                        savingsList={savings}
                        setSavings={setSavings}

                        dataYrke={configData.profession}
                        dataUtdaning={configData.education}
                        dataBolig={configData.fremtid_sparemal_bolig}
                        dataList={configData.fremtid_andre_sparemal}

                        costSalary={costSalary}
                        setCostSalary={setCostSalary}

                        costSalaryMonth={costSalaryMonth}
                        setCostSalaryMonth={setCostSalaryMonth}
                        formattedCostSalaryMonth={formattedCostSalaryMonth}
                        setFormattedCostSalaryMonth={setFormattedCostSalaryMonth}

                        taxrate={taxrate}
                        setTaxrate={setTaxrate}
                        income={income}
                        setIncome={setIncome}
                        taxrateSliderValue={taxrateSliderValue}
                        setTaxrateSliderValue={setTaxrateSliderValue}
                        formattedCostSalary={formattedCostSalary}
                        setFormattedCostSalary={setFormattedCostSalary}

                        /* Living expenses */
                        showLivingContent={showLivingContent}
                        setShowLivingContent={setShowLivingContent}
                        activeLivingOption={activeLivingOption}
                        setActiveLivingOption={setActiveLivingOption}

                        rentalPrice={rentalPrice}
                        setRentalPrice={setRentalPrice}
                        formattedRentalPrice={formattedRentalPrice}
                        setFormattedRentalPrice={setFormattedRentalPrice}

                        costInsurance={costInsurance}
                        setCostInsurance={setCostInsurance}
                        insuranceSliderValue={insuranceSliderValue}
                        setInsuranceSliderValue={setInsuranceSliderValue}

                        costPower={costPower}
                        setCostPower={setCostPower}
                        powerSliderValue={powerSliderValue}
                        setPowerSliderValue={setPowerSliderValue}

                        costTv={costTv}
                        setCostTv={setCostTv}
                        tvSliderValue={tvSliderValue}
                        setTvSliderValue={setTvSliderValue}

                        /* Transportation Expenses */
                        showCarExpenseContent={showCarExpenseContent}
                        setShowCarExpenseContent={setShowCarExpenseContent}

                        activeTransportationOption={activeTransportationOption}
                        setActiveTransportationOption={setActiveTransportationOption}
                        activeCarExpenseOption={activeCarExpenseOption}
                        setActiveCarExpenseOption={setActiveCarExpenseOption}

                        costCollective={costCollective}
                        setCostCollective={setCostCollective}
                        collectiveSliderValue={collectiveSliderValue}
                        setCollectiveSliderValue={setCollectiveSliderValue}

                        costCar={costCar}
                        setCostCar={setCostCar}
                        carSliderValue={carSliderValue}
                        setCarSliderValue={setCarSliderValue}

                        costCarExpense={costCarExpense}
                        setCostCarExpense={setCostCarExpense}
                        carExpenseSliderValue={carExpenseSliderValue}
                        setCarExpenseSliderValue={setCarExpenseSliderValue}

                        /* Comsumption Expenses */
                        costFood={costFood}
                        setCostFood={setCostFood}
                        foodSliderValue={foodSliderValue}
                        setFoodSliderValue={setFoodSliderValue}

                        costGrocery={costGrocery}
                        setCostGrocery={setCostGrocery}
                        grocerySliderValue={grocerySliderValue}
                        setGrocerySliderValue={setGrocerySliderValue}

                        costClothes={costClothes}
                        setCostClothes={setCostClothes}
                        clothesSliderValue={clothesSliderValue}
                        setClothesSliderValue={setClothesSliderValue}

                        costCare={costCare}
                        setCostCare={setCostCare}
                        careSliderValue={careSliderValue}
                        setCareSliderValue={setCareSliderValue}

                        costGaming={costGaming}
                        setCostGaming={setCostGaming}
                        gamingSliderValue={gamingSliderValue}
                        setGamingSliderValue={setGamingSliderValue}

                        costActivities={costActivities}
                        setCostActivities={setCostActivities}
                        activitiesSliderValue={activitiesSliderValue}
                        setActivitiesSliderValue={setActivitiesSliderValue}

                        /* costHousehold={costHousehold}
                        setCostHousehold={setCostHousehold}
                        householdSliderValue={householdSliderValue}
                        setHouseholdSliderValue={setHouseholdSliderValue} */

                        costFurniture={costFurniture}
                        setCostFurniture={setCostFurniture}
                        furnitureSliderValue={furnitureSliderValue}
                        setFurnitureSliderValue={setFurnitureSliderValue}

                        /* Loan and depts */
                        studentLoan={studentLoan}

                        /* Savings */
                        savingsNormal={savingsNormal}
                        setSavingsNormal={setSavingsNormal}

                        savingsBsu={savingsBsu}
                        setSavingsBsu={setSavingsBsu}
                        savingsBsuSliderValue={savingsBsuSliderValue}
                        setSavingsBsuSliderValue={setSavingsBsuSliderValue}

                        preBsu={preBsu}
                        setPreBsu={setPreBsu}

                        preSavings={preSavings}
                        setPreSavings={setPreSavings}

                        costHousing={costHousing}
                        setCostHousing={setCostHousing}
                        formattedCostHousing={formattedCostHousing}
                        setFormattedCostHousing={setFormattedCostHousing}

                        formattedSavingsNormal={formattedSavingsNormal}
                        setFormattedSavingsNormal={setFormattedSavingsNormal}
                        formattedPreSavings={formattedPreSavings}
                        setFormattedPreSavings={setFormattedPreSavings}
                        formattedPreBsu={formattedPreBsu}
                        setFormattedPreBsu={setFormattedPreBsu}

                        /* Footer */
                        endResult={endResult}
                        setEndResult={setEndResult}
                        savingsYear={savingsYear}
                        setSavingsYear={setSavingsYear}
                        savingsMonth={savingsMonth}
                        setSavingsMonth={setSavingsMonth}


                        operatingSystem={operatingSystem}

                        /* From Result.js */
                        savingsTitle={savingsTitle}
                        setSavingsTitle={setSavingsTitle}
                        savingsMessage={savingsMessage}
                        setSavingsMessage={setSavingsMessage}

                        activeResultOption={activeResultOption}
                        setActiveResultOption={setActiveResultOption}

                        setShowSlider={setShowSlider}
                        setActiveOption={setActiveOption}
                        setActivePreparedFeedback={setActivePreparedFeedback}
                        setConsumptionItems={setConsumptionItems}
                        setActivePreparedOption={setActivePreparedOption}
                        setTotalAmount={setTotalAmount}
                    />}
                />

                <Route path="/resultat"

                    element={<Result
                        endResult={endResult}

                        savingsList={savings}
                        setSavings={setSavings}
                        costHousing={costHousing}

                        savingsYear={savingsYear}
                        setSavingsYear={setSavingsYear}
                        savingsMonth={savingsMonth}
                        setSavingsMonth={setSavingsMonth}

                        savingsTitle={savingsTitle}
                        setSavingsTitle={setSavingsTitle}
                        savingsMessage={savingsMessage}
                        setSavingsMessage={setSavingsMessage}

                        activeResultOption={activeResultOption}
                        setActiveResultOption={setActiveResultOption}

                        rentalPrice={rentalPrice}
                        costInsurance={costInsurance}
                        costPower={costPower}
                        costTv={costTv}

                        activeTransportationOption={activeTransportationOption}
                        costCarExpense={costCarExpense}
                        costCollective={costCollective}

                        costFood={costFood}
                        costGrocery={costGrocery}
                        costCare={costCare}
                        costGaming={costGaming}
                        costActivities={costActivities}
                        costClothes={costClothes}
                        costFurniture={costFurniture}
                        /* costHousehold={costHousehold} */

                        costCar={costCar}
                        studentLoanMonth={studentLoanMonth}

                        savingsNormal={savingsNormal}
                        savingsBsu={savingsBsu}

                        preBsu={preBsu}
                        preSavings={preSavings}

                        operatingSystem={operatingSystem}
                    />}
                />

                <Route path="/forberedtKalkulator"

                    element={<PreparedCalculator
                        data={configData}

                        activePreparedOption={activePreparedOption}
                        setActivePreparedOption={setActivePreparedOption}
                        operatingSystem={operatingSystem}
                    />}
                />

                <Route path="/forberedtKalkulator2"

                    element={<PreparedCalculator2
                        data={configData}

                        activePreparedOption={activePreparedOption}
                        operatingSystem={operatingSystem}
                    />}
                />

                <Route path="/forbrukslan"

                    element={<ConsumptionLoan
                        data={configData}

                        activePreparedOption={activePreparedOption}
                        operatingSystem={operatingSystem}
                    />}
                />

                <Route path="/foreberedtTilbakemelding"

                    element={<PreparedFeedback
                        data={configData}

                        activePreparedFeedback={activePreparedFeedback}
                        setActivePreparedFeedback={setActivePreparedFeedback}
                        operatingSystem={operatingSystem}
                        activePreparedOption={activePreparedOption}
                    />}
                />

                <Route path="/forbrukskalkulator"

                    element={<ConsumptionCalculator
                        data={configData}

                        consumptionItems={consumptionItems}
                        setConsumptionItems={setConsumptionItems}
                        totalAmount={totalAmount}
                        setTotalAmount={setTotalAmount}
                        operatingSystem={operatingSystem}
                    />}
                />

                <Route path="/forbrukskalkulatorResultat"

                    element={<ConsumptionResult
                        data={configData}

                        totalAmount={totalAmount}
                        operatingSystem={operatingSystem}
                    />}
                />
            </Routes>

        </HashRouter>
    );
};

export default Config;