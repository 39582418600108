import React, { useState, useEffect } from "react";
import ButtonNext from "../components/ButtonNext";
import LinkNext from "../components/LinkNext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/sharp-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

export default function PreparedCalculator2(props) {
    const [story, setStory] = useState('');
    const [showButton, setShowButton] = useState(false);

    const [count, setCount] = useState(8);

     

    useEffect(() => {
        if (props.activePreparedOption === 0) {
            setStory(props.data.forberedtKalkulator.stories[0].story)
        } else if (props.activePreparedOption === 1) {
            setStory(props.data.forberedtKalkulator.stories[1].story)
        } else if (props.activePreparedOption === 2) {
            setStory(props.data.forberedtKalkulator.stories[2].story)
        } else {
            setStory(props.data.forberedtKalkulator.stories[3].story)
        }

        

        // Introduce a 10-second delay before showing the button
        const timer = setTimeout(() => {
            setShowButton(true);            
        }, 8000);

        
        const interval = setInterval(() => {
            setCount(prevCount => prevCount - 1);                
        }, 1000);
        
        
        // Clean up the timer on component unmount
        return () => {
            clearTimeout(timer);
            clearInterval(interval);
        };   

       
    }, [props.activePreparedOption]);
    
    const renderTextWithLineBreaks = (text) => {
        const paragraphs = text.split("\n").map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
        ));
        return paragraphs;
    };

    

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <section className="preparedBox bg-lightBlue w-100">
            <div className="bg-lightBlue">
                <LinkNext link="kalkulator?scrollToCalculator" >Lukk <FontAwesomeIcon icon="fa-solid fa-xmark" size="1x" className="ms-1" /></LinkNext>
                <div className="container">
                    <div className="row justify-content-center text-center pt-5">
                        <div className="col col-lg-6 col-xl-6 col-md-8">
                            <p className="fw-bold">Ting kan skje</p>
                            <h1 className="h1">Dette kan jeg <span className={`blueAfter ${props.operatingSystem ? "spanForMac" : ""}`}>Ikke</span> gå glipp av</h1>
                        </div>
                    </div>                    
                    <div className="row justify-content-center mt-5">
                        <div className="col col-lg-6 col-xl-6 col-md-8">
                            <p>{renderTextWithLineBreaks(story)}</p>
                        </div>
                    </div>

                   
                        <div className="row justify-content-center">
                            <div className="col col-lg-5 col-xl-4 col-md-7 justify-content-center d-flex">                                
                            {showButton ? 
                                <ButtonNext link="forbrukslan" text="Hva er forbrukslån?" />  
                                : 
                                <h6 className="h6 my-5 py-3">LES HISTORIEN ({count} sek)</h6> 
                            }   
                            </div>
                        </div>                   

                    <p cal></p>
                </div>
            </div>

        </section>
    )
}
