import React, { useEffect } from "react";

import ButtonNext from "../components/ButtonNext";
import LinkNext from "../components/LinkNext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/sharp-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

export default function ConsumptionLoan(props) {
    const data = props.data;

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

    return (
        <section className="preparedBox bg-lightBlue w-100 pt-5">
            <div className="bg-lightBlue">
                <LinkNext link="kalkulator?scrollToCalculator" >Lukk <FontAwesomeIcon icon="fa-solid fa-xmark" size="1x" className="ms-1" /></LinkNext>
                <div className="container">
                    <div className="row justify-content-center text-center pt-5">
                        <div className="col col-lg-6 col-xl-6 col-md-8">
                            <p className="fw-bold">Ting kan skje</p>
                            <h1 className="h1">Dette kan jeg <span className={`blueAfter ${props.operatingSystem ? "spanForMac" : ""}`}>Ikke</span> gå glipp av</h1>
                        </div>
                    </div>

                    {console.log(props.activePreparedOption)}

                    <div className="row justify-content-center mt-5 text-center">
                        <div className="col col-lg-6 col-xl-6 col-md-8">
                            <h5 class="text-uppercase fw-bold mb-0">{data.forberedtKalkulator.loan.think_paying_text}</h5>
                            <h2 class="h1 fw-light">{data.forberedtKalkulator.loan.outcomes.map((item, index) => (
                                index === props.activePreparedOption ? data.forberedtKalkulator.loan.outcomes[index].original_sum.toLocaleString('no') : ''
                            ))},-</h2>
                        </div>
                    </div>

                    <div className="row justify-content-center mt-2 text-center mb-3">
                        <div className="col col-lg-6 col-xl-6 col-md-8">
                            <h5 class="text-uppercase fw-bold mb-2">{data.forberedtKalkulator.loan.actually_paying_text}</h5>
                            <h1 class="h1"><span className={`blueAfter ${props.operatingSystem ? "spanForMac" : ""}`}>{data.forberedtKalkulator.loan.outcomes.map((item, index) => (
                                index === props.activePreparedOption ? data.forberedtKalkulator.loan.outcomes[index].loan_sum.toLocaleString('no') : ''
                            ))},-</span></h1>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-12 col-lg-6 offset-lg-3 col-xl-6 col-md-8 offset-md-2 consumptionLoanBox-col">
                            <div className="d-flex justify-content-between consumptionLoanBox py-2">
                                <p>Forbrukslån</p>
                                <p class="fw-bold">{data.forberedtKalkulator.loan.outcomes.map((item, index) => (
                                    index === props.activePreparedOption ? data.forberedtKalkulator.loan.outcomes[index].original_sum.toLocaleString('no') : ''
                                ))},-</p>
                            </div>

                        </div>
                        <div className="col-12 col-lg-6 offset-lg-3 col-xl-6 col-md-8 offset-md-2 consumptionLoanBox-col">
                            <div className="d-flex justify-content-between py-2 consumptionLoanBox">
                                <p>Rente</p>
                                <p class="fw-bold">{data.forberedtKalkulator.loan.rate.toLocaleString('no')}%</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 offset-lg-3 col-xl-6 col-md-8 offset-md-2 consumptionLoanBox-col">
                            <div className="d-flex justify-content-between py-2 consumptionLoanBox">
                                <p>Antall år med nedbetaling</p>
                                <p class="fw-bold">{data.forberedtKalkulator.loan.downpayment_year} ÅR</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 offset-lg-3 col-xl-6 col-md-8 offset-md-2 consumptionLoanBox-col">
                            <div className="d-flex justify-content-between py-2 consumptionLoanBox">
                                <p>Sum renter og gebyrer</p>
                                <p class="fw-bold">{data.forberedtKalkulator.loan.outcomes.map((item, index) => (
                                    index === props.activePreparedOption ? (data.forberedtKalkulator.loan.outcomes[index].loan_sum - data.forberedtKalkulator.loan.outcomes[index].original_sum).toLocaleString('no') : ''
                                ))},-</p>
                            </div>
                        </div>
                    </div>

                    <div className="row text-center">
                        <p class="fw-bold">{ }</p>
                    </div>


                    <div className="row justify-content-center">
                        <div className="col col-lg-5 col-xl-4 col-md-7 justify-content-center d-flex" style={{ paddingBottom: '4rem' }}>
                            <ButtonNext link="foreberedtTilbakemelding" class="mt-auto" marginY="my-2" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}