import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import CostFormLeft from '../components/CostFormLeft';
import Option from './Option';
import Range from './Range';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

const LivingExpenses = (props) => {
    const [accOpen, setAccOpen] = useState(false);

    const handleEntered = () => {
        setAccOpen(true);
    }

    /* OPTIONS */
    const handleOptionActive = (event, index, showSlider) => {
        props.setActiveLivingOption(index)
        props.setShowLivingContent(showSlider)

        sessionStorage.setItem('activeLivingOption', index);
        sessionStorage.setItem('showLivingContent', showSlider);

        if (index === 2) {
            props.setRentalPrice(10000)

            props.setCostInsurance(200)
            props.setInsuranceSliderValue(200)

            props.setCostPower(2000)
            props.setPowerSliderValue(2000)

            props.setCostTv(700)
            props.setTvSliderValue(700)
        } else if (index === 1) {
            props.setRentalPrice(5000)

            props.setCostInsurance(200)
            props.setInsuranceSliderValue(200)

            props.setCostPower(1000)
            props.setPowerSliderValue(1000)

            props.setCostTv(350)
            props.setTvSliderValue(350)
        } else {
            props.setRentalPrice(0)

            props.setCostInsurance(0)
            props.setInsuranceSliderValue(0)

            props.setCostPower(0)
            props.setPowerSliderValue(0)

            props.setCostTv(0)
            props.setTvSliderValue(0)
        }
    }

    /* INPUT RENT */
    const handleInputChange = (value) => {
        /* props.setRentalPrice(parseInt(value));
        if (value.length === 0) {
            props.setRentalPrice(0)
        } */

        const inputVal = value.replace(/\D/g, ''); // Remove non-numeric characters

        if (!inputVal || isNaN(inputVal)) {
            props.setFormattedRentalPrice('');
            props.setRentalPrice(0)
        } else {
            const numericVal = parseInt(inputVal, 10);
            const formatted = numericVal.toLocaleString('no'); // Format number with thousand separator
            props.setFormattedRentalPrice(formatted);
            props.setRentalPrice(numericVal);
        }
    }

    /* SLIDER INSURANCE */
    const handleSliderChangeInsurance = (event) => {
        event.preventDefault();
        const value = (event.target.value);

        props.setInsuranceSliderValue(value);
    }

    const insurance = props.insuranceSliderValue;
    useEffect(() => {
        props.setCostInsurance(parseInt(insurance));
    }, [insurance, props.setCostInsurance]);
    /* ---------------- */

    /* SLIDER POWER AND ELECTRICITY */
    const handleSliderChangePower = (event) => {
        event.preventDefault();
        const value = (event.target.value);

        props.setPowerSliderValue(value);
    }

    const power = props.powerSliderValue;
    useEffect(() => {
        props.setCostPower(parseInt(power));
    }, [power, props.setCostPower]);
    /* ---------------------------- */

    /* SLIDER POWER AND ELECTRICITY */
    const handleSliderChangeTv = (event) => {
        event.preventDefault();
        const value = (event.target.value);

        props.setTvSliderValue(value);
    }

    const tv = props.tvSliderValue;
    useEffect(() => {
        props.setCostTv(tv);
    }, [tv, props.setCostTv]);
    /* ----------------------------- */

    return (
        <Accordion.Item eventKey="1" className={props.showActive}>
            <div className="row justify-content-center accordion-header-style">
                <div className={props.expand}>
                    <Accordion.Header className='w-100'>
                        <div className="calc-list_header d-flex justify-content-between align-items-center w-100">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon="fa-sharp fa-solid fa-house-building" size="lg" style={{ color: "#142656", }} />
                                <label className="ms-3 calc-acc-label">Boutgifter</label>
                            </div>
                            <label className="ms-auto me-3 fw-bold">{+props.rentalPrice > 0 || +props.costInsurance > 0 || +props.costPower > 0 || +props.costTv > 0 ? '-' : null} {(
                                (+props.rentalPrice +
                                    +props.costInsurance +
                                    +props.costPower +
                                    +props.costTv).toLocaleString('no')
                            )},-</label>
                        </div>
                    </Accordion.Header>
                </div>
            </div>

            <Accordion.Body in={accOpen} onEntered={handleEntered} className='pt-5 px-0'>
               
                    <Option
                        data={props.data.kalkulator_boutgifter.bo_situasjon}
                        activeOption={props.activeLivingOption}
                        setActiveOption={props.setActiveLivingOption}
                        handleOptionActive={handleOptionActive}
                    />

                    {sessionStorage.getItem('showLivingContent') !== '0' ?
                        <>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-6 col-xl-6 col-md-8">
                                    <hr className='my-4'></hr>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-6 col-xl-6 col-md-8 mb-5">
                                    <div className="d-flex bg-lightGreen p-3 green-tips">
                                        <FontAwesomeIcon icon="lightbulb" inverse="100%" className="bg-green p-2 rounded-5 infobox-icon" />
                                        <div className="text-start ps-3">
                                            {/* Title for infobox */}
                                            <span className="fw-bold">{props.data.kalkulator_boutgifter.bo_situasjon.infobox[+props.activeLivingOption].title}</span>
                                            <br />
                                            {/* Message for infobox */}
                                            <span className='d-block mb-2'>{props.data.kalkulator_boutgifter.bo_situasjon.infobox[+props.activeLivingOption].message}</span>
                                            <a href={props.data.kalkulator_boutgifter.bo_situasjon.infobox[+props.activeLivingOption].link.url} target='_blank' className="fw-bold h6">
                                                {props.data.kalkulator_boutgifter.bo_situasjon.infobox[+props.activeLivingOption].link.title}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <CostFormLeft
                                data={props.data.kalkulator_boutgifter.leilighet.leiepris}
                                value={props.formattedRentalPrice}
                                onChange={(event) => handleInputChange(event.target.value)}
                                placeholder={props.rentalPrice}
                            />

                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    <div className="mb-5 mt-3">
                                        <a className="h5" href={props.data.kalkulator_boutgifter.leilighet.leiepris.link_bottom.url} rel="noreferrer" target="_blank">
                                            {props.data.kalkulator_boutgifter.leilighet.leiepris.link_bottom.title}
                                        </a>
                                    </div>
                                </div>
                            </div>



                            {/* INSURANCE */}
                            <div className="row justify-content-center mb-3">
                                {accOpen ? (
                                    <Range
                                        data={props.data.kalkulator_boutgifter.leilighet.innboforsikring}
                                        onChange={handleSliderChangeInsurance}
                                        sliderValue={props.insuranceSliderValue}
                                        className="position-relative"
                                        object="kr"
                                        id="insuranceRange"
                                        accOpen={accOpen}
                                    />
                                ) : (
                                    <Range
                                        data={props.data.kalkulator_boutgifter.leilighet.innboforsikring}
                                        onChange={handleSliderChangeInsurance}
                                        sliderValue={props.insuranceSliderValue}
                                        className="position-relative"
                                        object="kr"
                                        id="insuranceRange"
                                        accOpen={accOpen}
                                    />
                                )}

                            </div>

                            {/* POWER, ELECTRICITY AND WATER */}
                            <div className="row justify-content-center">
                                {accOpen ? (
                                    <Range
                                        data={props.data.kalkulator_boutgifter.leilighet.strom_vann_og_oppvarming}
                                        onChange={handleSliderChangePower}
                                        sliderValue={props.powerSliderValue}
                                        className="position-relative"
                                        object="kr"
                                        id="powerRange"
                                        accOpen={accOpen}
                                    />
                                ) : (
                                    <Range
                                        data={props.data.kalkulator_boutgifter.leilighet.strom_vann_og_oppvarming}
                                        onChange={handleSliderChangePower}
                                        sliderValue={props.powerSliderValue}
                                        className="position-relative"
                                        object="kr"
                                        id="powerRange"
                                        accOpen={accOpen}
                                    />
                                )}

                            </div>

                            {/* TV AND INTERNETT */}
                            <div className="row justify-content-center">
                                {accOpen ? (
                                    <Range
                                        data={props.data.kalkulator_boutgifter.leilighet.tv_og_internett}
                                        onChange={handleSliderChangeTv}
                                        sliderValue={props.tvSliderValue}
                                        className="position-relative"
                                        object="kr"
                                        id="tvRange"
                                        accOpen={accOpen}
                                    />
                                ) : (
                                    <Range
                                        data={props.data.kalkulator_boutgifter.leilighet.tv_og_internett}
                                        onChange={handleSliderChangeTv}
                                        sliderValue={props.tvSliderValue}
                                        className="position-relative"
                                        object="kr"
                                        id="tvRange"
                                        accOpen={accOpen}
                                    />
                                )}

                            </div>

                        </> : null}
               
            </Accordion.Body>
        </Accordion.Item>
    );
};

export default LivingExpenses;