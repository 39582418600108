import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/sharp-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

const Option = (props) => {
    const [showInfo, setShowInfo] = useState(false);
    const [showInfoText, setShowInfoText] = useState('Mer info');

    const handleInfoClick = () => {

        if (showInfo) {
            setShowInfo(false);
            setShowInfoText('Mer info')
        } else {
            setShowInfo(true);
            setShowInfoText('Lukk')
        }
    }

    return (
        <>
            <div className="row justify-content-center">
                <div className="d-flex mb-2 col col-lg-6 col-xl-6 col-md-8 mb-0 options">


                    <h5 className={`h5 fw-bold w-100 text-${props.textCenter ? 'center' : 'start'} mb-0`} >{props.data.title}</h5>

                    <div className="col-auto my-auto">
                        {props.data.questionmark.show ? (
                            <div className="d-flex merInfo" onClick={handleInfoClick}>
                                <p className="unbreakable me-1 mb-0 text-decoration-underline">{showInfoText}</p>
                                <FontAwesomeIcon icon="fa-solid fa-question" size="sm" className="rounded-5 p-1" />
                            </div>
                        ) : (
                            null
                        )}
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col col-lg-6 col-xl-6 col-md-8">
                    <p className={`text-${props.textCenter ? 'center' : 'start'}`}>{props.data.subtitle}</p>
                </div>
            </div>



            {showInfo ? (
                <div className="row justify-content-center">
                    <div className="col col-lg-6 col-xl-6 col-md-8">
                        <div className="bg-white p-3 text-start infoBox mb-3 d-flex flex-column">
                            <span className="fw-bold">{props.data.questionmark.title}</span>
                            <p>{props.data.questionmark.text}</p>
                            <a href={props.data.questionmark.link.url} className="opacity-50 ms-auto" target="_blank">{props.data.questionmark.link.title}<FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" size="s" className="ms-2" /></a>
                        </div>
                    </div>
                </div>
            ) : (
                null
            )}




            {props.data.options.length >= 4 ?

                <div className="row justify-content-center option g-3">

                    {props.data.options.map((item, index) => (
                        <div key={item.id ? item.id : index}
                            className="col-6 col-lg-5">
                            <div className={`text-center options_item mb-4 optionContent pt-3 pb-2 px-1 h-100 d-flex flex-column justify-content-center align-items-center ${props.activeOption === index ? 'optionActive optionActiveMore' : ''}`}
                                onClick={(event) => props.handleOptionActive(event, index, item.showSlider)} style={{ backgroundColor: "rgba(255,255,255,.6)" }}>
                                <FontAwesomeIcon icon={item.icon} size={props.data.options.length >= 4 ? 'xl' : 'lg'} className="mb-3" />
                                <h6 className="h6 mb-1">{item.title} {item.subtitle}</h6>
                                {item.text ? <p className='small'>{item.text}</p> : null}
                            </div>


                        </div>
                    ))}

                </div>

                :
                <div className="row justify-content-center option">
                    <div className="col col-lg-6 col-xl-6 col-md-8 d-flex justify-content-between option">

                        {props.data.options.map((item, index) => (
                            <div key={item.id ? item.id : index} style={{ flex: 1 }}
                                className={`text-center options_item mb-4 ${index !== props.data.options.length - 1 ? 'me-3' : ''} optionContent pt-3 pb-2 px-1 h-100 bg-white d-flex flex-column justify-content-center align-items-center ${parseInt(props.activeOption) === index ? 'optionActive' : ''}`}
                                onClick={(event) => props.handleOptionActive(event, index, item.showSlider)}>
                                <FontAwesomeIcon icon={item.icon} size={props.data.options.length >= 4 ? 'xl' : 'lg'} className="mb-3" />
                                <h6 className="h6 mb-1">{item.title} {item.subtitle}</h6>
                                {item.text ? <p className='small'>{item.text}</p> : null}
                            </div>
                        ))}
                    </div>
                </div>}

        </>


    );
};

export default Option; 