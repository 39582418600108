import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import Range from "../components/Range";
import CostFormLeft from '../components/CostFormLeft';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

const Savings = (props) => {

    const [accOpen, setAccOpen] = useState(false);

    const handleEntered = () => {
        setAccOpen(true);
    }


    const handleSavingsChange = (e) => {
        const inputVal = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters

        if (!inputVal || isNaN(inputVal)) {
            props.setFormattedSavingsNormal('');
            props.setSavingsNormal(0);
        } else {
            const numericVal = parseInt(inputVal, 10);
            const formatted = numericVal.toLocaleString('no'); // Format number with thousand separator
            props.setFormattedSavingsNormal(formatted);
            props.setSavingsNormal(numericVal);
        }
    }

    const handlePreBsuChange = (e) => {
        const inputVal = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters

        if (!inputVal || isNaN(inputVal)) {
            props.setFormattedPreBsu('');
            props.setPreBsu(0)
        } else {
            const numericVal = parseInt(inputVal, 10);
            const formatted = numericVal.toLocaleString('no'); // Format number with thousand separator
            props.setFormattedPreBsu(formatted);
            props.setPreBsu(numericVal);
        }
    }

    const handlePreSavingsChange = (e) => {
        const inputVal = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters

        if (!inputVal || isNaN(inputVal)) {
            props.setFormattedPreSavings('');
            props.setPreSavings(0)
        } else {
            const numericVal = parseInt(inputVal, 10);
            const formatted = numericVal.toLocaleString('no'); // Format number with thousand separator
            props.setFormattedPreSavings(formatted);
            props.setPreSavings(numericVal);
        }
    }

    /* BSU */
    const handleBsuChange = (event) => {
        event.preventDefault();
        const value = (event.target.value)

        props.setSavingsBsuSliderValue(value)
    }

    const bsu = props.savingsBsuSliderValue;
    useEffect(() => {
        props.setSavingsBsu(bsu);
    }, [bsu, props.setSavingsBsu])
    /* --------------- */

    return (
        <Accordion.Item eventKey="5" className={props.showActive}>

            <div className="row justify-content-center accordion-header-style">
                <div className={props.expand}>
                    <Accordion.Header className='w-100'>
                        <div className="calc-list_header d-flex justify-content-between align-items-center w-100">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon="fa-sharp fa-solid fa-piggy-bank" size="lg" style={{ color: "#142656", }} />
                                <label className="ms-3 calc-acc-label">Sparing</label>
                            </div>
                            <label className="ms-auto me-3 fw-bold">
                                {props.savingsNormal || props.savingsBsu > 0 ? '- ' : null}
                                {(+props.savingsNormal + +props.savingsBsu).toLocaleString('no')
                                }
                                ,-</label>
                        </div>
                    </Accordion.Header>
                </div>
            </div>

            <Accordion.Body in={accOpen}
                onEntered={handleEntered} className='py-5 px-0'>


                <CostFormLeft
                    data={props.data.kalkulator_spare.manedlig_sparesum}
                    onChange={handleSavingsChange}
                    value={props.formattedSavingsNormal}
                    showInfoBox={true}
                />


                <div className="row justify-content-center mt-5">
                    {/* <RangeLeft
                            data={props.data.kalkulator_spare.manedlig_sparing_bsu}
                            onChange={handleBsuChange}
                            sliderValue={props.savingsBsuSliderValue}
                            object="kr"
                        /> */}
                    {accOpen ?
                        <Range
                            data={props.data.kalkulator_spare.manedlig_sparing_bsu}
                            onChange={handleBsuChange}
                            sliderValue={props.savingsBsuSliderValue}
                            className="position-relative"
                            object=",– MND"
                            id="savingRange"
                            accOpen={accOpen}
                        />
                        :
                        <Range
                            data={props.data.kalkulator_spare.manedlig_sparing_bsu}
                            onChange={handleBsuChange}
                            sliderValue="2300"
                            className="position-relative"
                            object="kr"
                            id="savingRange"
                            accOpen={accOpen}
                        />}
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6 col-xl-6 col-md-8">
                        <hr className="my-4" />
                    </div>
                </div>
                <div className="row justify-content-center mt-3 mb-5">
                    <div className="col-12 col-lg-6 col-xl-6 col-md-8">
                        <label className="fw-bold">{props.data.kalkulator_spare.text.title}</label>
                        <p>{props.data.kalkulator_spare.text.message}</p>
                    </div>
                </div>


                <CostFormLeft
                    data={props.data.kalkulator_spare.pre_bsu}
                    onChange={handlePreBsuChange}
                    value={props.formattedPreBsu}
                />

                <div className="mb-3"></div>

                <CostFormLeft
                    data={props.data.kalkulator_spare.pre_sparekonto}
                    onChange={handlePreSavingsChange}
                    value={props.formattedPreSavings}
                />


            </Accordion.Body>
        </Accordion.Item>
    );
};

export default Savings;