import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

const OptionSmall = (props) => {

    return (
            <div className="row justify-content-center mb-5 options">
                <div className="col col-lg-6 col-xl-6 col-md-8">              
                    <h5 className="h5 fw-bold text-center mb-4 w-100">{props.data.title}</h5>
                    <div className="d-flex justify-content-between optionSmall bg-lightGray py-2 px-2">
                        {props.data.options.map((item, index) => (
                            <div className={`py-3 ${parseInt(props.activeOption) === index ? 'optionSmallActive text-white' : 'optionSmallNotActive'}`}  
                            key={item.id ? item.id : index}  onClick={(event) => props.handleOptionActive(event, index)} style={{flex:1}}>
                                <h6 className={`py-0 h6 mb-0 fw-bold w-100 text-center options_item lh-1`}  style={{marginTop:"4px"}}>
                                {item.title}
                                    
                                </h6>
                            </div>
                        ))}
                    </div>                                            
                </div>
            </div>                    
    );
};

export default OptionSmall; 