import React from "react";
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default function ButtonNext(props) {
    return (
        <p className={`button-next text-center d-inline-block ${props.marginY ? props.marginY : 'my-5'}`} style={{minWidth: "170px"}}>                                       
            <Link to={`/${props.link}`} className={`d-flex align-items-center justify-content-center btn rounded-pill w-100 py-3 px-3  ${props.disabled ? 'fw-bold form_button__item-disabled' : 'fw-semibold text-white form_button__item bg-darkBlue'}`}>
                {props.text ? props.text : 'Videre'}
                <FontAwesomeIcon icon="fa-solid fa-arrow-right" size="xs" className="ms-2" />
            </Link>                    
        </p>
                   
    )
}