import React, { useState } from "react";
import Header from "../components/Header"
import ButtonNext from "../components/ButtonNext";
import ListSavings from "../components/ListSavings";
import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import NewSaving from "../components/NewSaving";
import ConcentModal from "../components/ConcentModal";

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

export default function SavingsList(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleAddSavings = (savings) => {
        props.setSavings((prevSavings) => {
            return [...prevSavings, savings];
        });
    }

    const handleDelete = (id) => {
        const newSavings = props.savingsList.filter(item => item.id !== id);

        props.setSavings(newSavings)
    }

    return (
        <div className="container">

            <Header title="Sparemål" back="sparemal" forward="oppsummering" />


            <div className="row justify-content-center mb-5">
                <div className="col col-lg-8 col-xl-6 col-md-8">
                    <h1 className="h1 text-center text-uppercase">
                        HVA ANNET <br className="header-br" /><span className={`greyAfter ${props.operatingSystem ? "spanForMac" : ""}`}>ØNSKER</span> DU DEG?
                    </h1>



                    <div className="text-center mt-4">
                        Egen bolig er fint, men det tar ofte tid
                        å nå boligdrømmen. Sett deg gjerne
                        noen mindre sparemål også.
                    </div>
                </div>
            </div>



            {/* Listekomponent */}
            <div className="row justify-content-center">
                <div className="col col-lg-8 col-xl-6 col-md-8">
                    <div className="pb-3 d-flex justify-content-between align-items-center border-bottom">
                        <h5 className="h5 fw-bold">DINE SPAREMÅL</h5>
                        <FontAwesomeIcon
                            icon="fa-plus"
                            inverse="100%"
                            className="bg-darkBlue p-2 rounded-5 addSavingsIcon"
                            onClick={handleShow}
                        ></FontAwesomeIcon>
                    </div>

                    <ListSavings
                        savingsList={props.savingsList}
                        handleDelete={handleDelete}
                        showDelete={true}
                    />
                </div>


            </div>

            {/* New item Overlay */}
            <Modal show={show} onHide={handleClose} fullscreen={true}>
                <Modal.Body>
                    <NewSaving
                        onAddSavings={handleAddSavings}
                        handleClose={handleClose}
                        data={props.data.fremtid_andre_sparemal.lag_et_sparemal}
                        savingsList={props.savingsList}
                    />
                </Modal.Body>
            </Modal>

            <div className="row justify-content-center">
                <div className="col col-lg-5 col-xl-4 col-md-7 justify-content-center d-flex">
                    <ButtonNext link="oppsummering" />
                </div>
            </div>   

            <ConcentModal />         
        </div>
    )
}