import React, { useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

const ListSavings = (props) => {

    const handleCheckboxChange = (itemId) => {
        props.setSavings((prevItems) =>
            prevItems.map((item) =>
                item.id === itemId ? { ...item, checked: !item.checked } : item
            )
        );
    };

    return (
        <ul className='ps-0'>
            {props.savingsList.map((item, index) => (
                <li key={index} className={`d-flex align-items-center ${index === 0 ? 'border-top border-bottom' : 'border-bottom'}  py-3`}>
                    {props.showCheckbox ?

                        <label class="custom-checkbox d-flex align-items-center me-2">
                            <input
                                type="checkbox"
                                id={item.id}
                                checked={item.checked}
                                onChange={() => handleCheckboxChange(item.id)}
                            />
                            <span class="custom-checkbox-box"></span>
                        </label> : ''}
                    <FontAwesomeIcon className="me-3" icon={item.icon} size="lg"></FontAwesomeIcon>
                    <h6 className="mb-0 text-capitalize">{item.title}</h6>
                    <h6 className="ms-auto mb-0 text-capitalize">{Math.round(item.price).toLocaleString('no')},-</h6>
                    {props.showDelete && item.showDelete != false ? <FontAwesomeIcon className="ms-3 trashcanIcon" icon="fa-trash" onClick={() => props.handleDelete(item.id)}></FontAwesomeIcon> : <FontAwesomeIcon className="ms-3 opacity-0" icon="fa-trash" />}
                </li>
            ))
            }
        </ul >
    );
};

export default ListSavings;